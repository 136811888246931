import React, { useState, useEffect, useCallback, useMemo } from "react";
import Slider from "react-slick/lib/slider";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import SliderDots from "./SliderDots";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./weekly-single-ads.scss";
import { getBannerAdsData, getWeeklyadgallery } from "redux/actions";
import AdsSpaceSection from "./AdsSpaceSection";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";
import { getLocalStorageValue } from 'config/helper';
import WeeklyAdSpaceSectionRightSide from "./WeeklyAdSpaceSectionRightSide";

const Arrow = ({ onClick, direction }) => (
  <div className={`cus-slider-arrow-design ${direction}-arrow-align`} onClick={onClick}>
    <i className={`fa-solid fa-${direction === 'right' ? 'right' : 'left'}-long`}></i>
  </div>
);

const WeeklySingleAds = () => {
  const { storeid, storename } = useParams();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const currentStoreID = useSelector(({ store }) => store.currentStoreID);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [activeDots, setActiveDots] = useState(1);

  const settings = useMemo(() => ({
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <Arrow direction="right" />,
    prevArrow: <Arrow direction="left" />,
    slidesToShow: 1,
    slidesToScroll: 1,
  }), []);

  const { bannerAdsData, bannerAdsDataLoading } = useSelector(({ bannerAds }) => bannerAds);
  const { weeklyadgalleryData, weeklyadgalleryDataLoading } = useSelector(({ weeklyadgallery }) => weeklyadgallery);

  const fetchWeeklyAdGallery = useCallback(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeid,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    dispatch(getWeeklyadgallery(body));
  }, [clientId, storeid, isUserLoggedIn, memberNumber, userToken, dispatch]);

  const fetchBannerAdsData = useCallback(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: isUserLoggedIn ? memberNumber : "",
      category_ids: "",
      search: "",
      funtional_text: "",
      placement: "run-of-site-banner-x",
      ros:"Weekly Ad"
    };
    dispatch(getBannerAdsData(body));
  }, [clientId, storeId, isUserLoggedIn, memberNumber, dispatch]);

  useEffect(() => {
    fetchWeeklyAdGallery();
    fetchBannerAdsData();
  }, [fetchWeeklyAdGallery, fetchBannerAdsData]);

  useEffect(() => {
    const checkViewportWidth = () => setIsMobile(window.innerWidth < 769);
    checkViewportWidth();
    window.addEventListener('resize', checkViewportWidth);
    return () => window.removeEventListener('resize', checkViewportWidth);
  }, []);

  const filteredBanners = useMemo(() => {
    return bannerAdsData?.ads?.find(ad => ad.slotId === "Horizontal Banners")?.banners ?? [];
  }, [bannerAdsData]);

  const dots = useMemo(() => Array.from({ length: weeklyadgalleryData?.data?.length || 10 }, (_, i) => i + 1), [weeklyadgalleryData]);

  const handleActiveDot = (dot) => setActiveDots(dot);
  const onPrev = () => setActiveDots(prev => Math.max(prev - 1, 1));
  const onNext = () => setActiveDots(prev => Math.min(prev + 1, dots.length));

  const weeklygallerySingleData = useMemo(() => weeklyadgalleryData?.data?.find(
    (galleryData) => galleryData.page_number === activeDots
  ), [weeklyadgalleryData, activeDots]);

  const breadcrumbList = useMemo(() => [
    { lable: "Weekly Ads", link: "/weekly-ads", searchTag: "" },
    { lable: storename, link: "", searchTag: "" },
  ], [storename]);

  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Weekly Ads" />
      <div className="weekly-ads-inner-section">
        <div className="container">
          {!bannerAdsDataLoading && filteredBanners[0] && (
            <AdsSpaceSectionMainTheme2 isMobile={isMobile} adsDetails={filteredBanners[0]} />
          )}
          {!weeklyadgalleryDataLoading && (
            <div className="weekly-ads-wrapper">
              <AdsSpaceSection adImage={weeklyadgalleryData?.ads_section} />
              <div className="weekly-ads-wrapper-inner">
                <div className="weekly-add-inner-slider">
                  <SliderDots
                    pages={dots.length}
                    activeSlide={activeDots}
                    handleActiveDot={handleActiveDot}
                    onPrev={onPrev}
                    onNext={onNext}
                  />
                  {weeklyadgalleryData?.ad_start_date && weeklyadgalleryData?.ad_end_date && (
                    <h3 className="valid-weekly-ad-item-price display-none">
                      Prices valid from {weeklyadgalleryData.ad_start_date} - {weeklyadgalleryData.ad_end_date}
                    </h3>
                  )}
                  <Slider {...settings}>
                    {weeklygallerySingleData && (
                      <div className="ads-image-style" key={weeklygallerySingleData.page_number}>
                        <img src={weeklygallerySingleData.url} alt="AdsBanner" />
                      </div>
                    )}
                  </Slider>
                  <SliderDots
                    pages={dots.length}
                    activeSlide={activeDots}
                    handleActiveDot={handleActiveDot}
                    onPrev={onPrev}
                    onNext={onNext}
                  />
                </div>
                <WeeklyAdSpaceSectionRightSide couponList={weeklyadgalleryData?.coupons_section} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WeeklySingleAds;