import CouponCardTheme2 from "components/CouponCardTheme2";
import React from "react";
import "./weekly-ad-space-right-section.scss";
// import CouponCard from "components/CouponCard";

const WeeklyAdSpaceSectionRightSide = ({ couponList }) => {
  return (
    <>
      {
        couponList.length > 0 ? (
          <div className="ads-space-section-weekly Add-right-side-wrapper">
            <h3 className="weekly-ad-right-side-special-promotion">
              Special Promotions
            </h3>
            <div className="ads-space-section-weekly Add-right-side">
              {couponList.map((coupon) => {
                return (
                  <div className="weekly-ad-coupon-card">
                    {/* <CouponCard coupon={coupon} /> */}
                    <CouponCardTheme2 coupon={coupon} />
                  </div>
                );
              })}
            </div>
          </div>
        ) : ("")}
    </>
  );
};
export default WeeklyAdSpaceSectionRightSide;
