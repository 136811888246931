import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick/lib/slider";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import ProductCard from "components/ProductCard";
import ProductCardTheme2 from "components/ProductCardTheme2";
import { Link } from "react-router-dom";

const FeatureProductSlider = ({ product }) => {

  const { theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: Math.min(product.items.length, 6),
    slidesToShow: 5,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="sellerpage-title" key={product.id}>
        <h1>{product.name}</h1>
        <Link to={`/departments/${product.url}`}>{product.url_label}</Link>
      </div>
      <Slider {...settings}>
        {theme_type === "1" ? (
          product.items.map((product, index) => {
            return (
              <>
                <ProductCard product={product} key={product.id} />
              </>
            );
          })
        ) : (
          product.items.map((product, index) => {
            return (
              <>
                <ProductCardTheme2 product={product} key={product.id} />
              </>
            );
          })
        )}
      </Slider>
    </>
  );
};
export default FeatureProductSlider;
