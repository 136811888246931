import { getLocalStorageValue } from 'config/helper';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mediaAnalyticsClick, mediaAnalyticsImpression } from 'redux/actions';

const Slide = ({ el, onOpenModal, isMobile }) => {
  const bannerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatchedCitrusAdIds = new Set();
  const dispatchedClickedCitrusAdIds = new Set();
  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const memberNumber = getLocalStorageValue("member-number");

  useEffect(() => {
    const handleClick = (event) => {
      event.preventDefault();
      const citrusAdId = event.currentTarget.getAttribute('data-citrus-ad-id');
      if (citrusAdId && !dispatchedClickedCitrusAdIds.has(citrusAdId)) {
        dispatch(mediaAnalyticsClick(citrusAdId, clientId, isUserLoggedIn ? memberNumber : ""));
        dispatchedClickedCitrusAdIds.add(citrusAdId);
      }
    };
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const citrusAdId = entry.target.getAttribute('data-citrus-ad-id');
          if (citrusAdId && !dispatchedCitrusAdIds.has(citrusAdId)) {
            dispatch(mediaAnalyticsImpression(citrusAdId,clientId, isUserLoggedIn ? memberNumber : "" ));
            dispatchedCitrusAdIds.add(citrusAdId);
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (bannerRef.current) {
      bannerRef.current.addEventListener('click', handleClick);
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        bannerRef.current.removeEventListener('click', handleClick);
        observer.unobserve(bannerRef.current);
      }
    };
  }, [dispatch]);

  const handleAnchorClick = (event) => {
    event.preventDefault();
    if (el.gtins && el.gtins.length > 0) {
      onOpenModal(el.gtins);
    } else {
      const isFullyQualifiedUrl = /^https?:\/\//i.test(el.ctaLink);
      if (isFullyQualifiedUrl) {
        window.open(el.ctaLink, "_blank");
      } else {
        navigate(el.ctaLink);
      }
    }
  };

  const handleMouseDown = (event) => {
    if (event.button === 1 || event.button === 2) {
      const citrusAdId = event.currentTarget.getAttribute('data-citrus-ad-id');
      if (citrusAdId && !dispatchedClickedCitrusAdIds.has(citrusAdId)) {
        dispatch(mediaAnalyticsClick(citrusAdId, clientId, isUserLoggedIn ? memberNumber : ""));
        dispatchedClickedCitrusAdIds.add(citrusAdId);
      }
    }
  };

  return (
    <div>
      <div
        className="singlebanner-banner"
        key={el.citrusAdId}
        onMouseDown={(e) => handleMouseDown(e)}
        ref={bannerRef} data-citrus-ad-id={el.citrusAdId}>
        <a
          href={el.ctaLink}
          onClick={(e) => handleAnchorClick(e)}
          target="_blank"
        >
          <img src={isMobile ? el.heroImage : el.backgroundImage} alt="Single Banner" />
        </a>
      </div>
    </div>
  );
};

export default Slide;
