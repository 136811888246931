import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import ProductListingPageSidebar from "components/ProductListingPage/Sidebar";
import ProductList from "components/ProductList";
import ProductListingPageAction from "components/ProductListingPage/Actions";
import { useParams, useSearchParams } from "react-router-dom";
import HotOffers from "components/HotOffers";
import {
  getHotOffersData,
  getFilterOptionList,
  getProductListByDepartment,
  getDepartmentTree,
  resetFilterSideBar,
  updateIftCheckList,
  updateSearchType,
  updateSearchText
} from "redux/actions";
import "./individual-department.scss";
import AdsSpaceSection from "./AdsSpaceSection";
import AdsSpaceSectionMain from "./AdsSpaceSectionMain";
import { getLocalStorageValue } from 'config/helper';

const IndividualDepartment = () => {
  const { departmentSlug } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const function_names = searchParams.get("function_names");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const lastVisitedPageNo = +getLocalStorageValue("last_visited_page_no")

  const {
    activePageNo,
    sortByValue,
    iftCheckedList,
    filterOptionList,
    pageLimit: pageProductCount,
    memoryToken,
  } = useSelector(({ product }) => product);

  const { searchText } =
    useSelector(({ search }) => search);


  const sortByOptionList = filterOptionList.sort_by_section;
  const selectedSortByOption =
    sortByOptionList &&
    sortByOptionList.find((option) => option.sort_id === sortByValue);

  const sortBy = selectedSortByOption && selectedSortByOption.field_name;

  const ecomData = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section.sub_department
  );

  const departmentDetails =
    ecomData && ecomData.find((ecom) => ecom.slug === departmentSlug);

  const {
    minPriceValue,
    maxPriceValue,
    departmentTree,
    productListByDepartmentTotalCount,
    categoryadsSection,
    productListByDepartmentLoading
  } = useSelector(({ product }) => product);

  const [pageLimit, setPageLimit] = useState("");

  const breadcrumbList = [
    { lable: "Department", link: "", searchTag: "" },
    {
      lable: departmentDetails && departmentDetails.name,
      link: "",
      searchTag: "",
    },
  ];

  useEffect(() => {
    if (departmentSlug && departmentDetails) {
      const { ift } = departmentDetails;
      if(ift === "sales" || ift === "my_favorites"){
       dispatch(updateIftCheckList(ift));
      }
    }
  }, [departmentSlug]); // eslint-disable-line


  useEffect(() => {
    if (departmentSlug) {
      dispatch(updateSearchType(departmentSlug));
      dispatch(updateSearchText(""));
    }
  }, [departmentSlug]); // eslint-disable-line


  useEffect(() => {
    if (!pageProductCount) {
      setPageLimit("100");
    }
  }, [pageProductCount]); // eslint-disable-line

  useEffect(() => {
    return () => {
      dispatch(resetFilterSideBar());
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getFilterOptionList(body));
  }, [storeId, isUserLoggedIn]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getHotOffersData(body));
  }, [storeId, isUserLoggedIn]); // eslint-disable-line

  const iftCheckedListJoin = iftCheckedList.join("|");

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      PageNo: lastVisitedPageNo || activePageNo,
      category_ids: departmentDetails && departmentDetails.category_ids,
      category_name: departmentDetails?.category_ids ? departmentDetails?.name : "",
      ift: iftCheckedListJoin,
      limit: pageLimit,
      sort_by: sortBy || "",
      min_price: minPriceValue.toString(),
      max_price: maxPriceValue.toString(),
      funtional_text: "",
      funtional_level: "",
      function_names: function_names || "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      search: searchText ? searchText : "",
      memoryToken: (lastVisitedPageNo || activePageNo) > 1 ? memoryToken : "",
    };
    dispatch(getProductListByDepartment(body));
  }, [
    dispatch,
    iftCheckedListJoin,
    sortBy,
    pageLimit,
    departmentDetails,
    lastVisitedPageNo,
    activePageNo,
    minPriceValue,
    maxPriceValue,
    storeId,
    function_names,
    clientId,
    isUserLoggedIn,
    memberNumber,
    userToken,
    searchText
  ]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      search: "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      category_ids: departmentDetails && departmentDetails.category_ids,
    };
    dispatch(getDepartmentTree(body));
  }, [storeId, isUserLoggedIn, memberNumber, userToken, departmentDetails]); // eslint-disable-line

  const hotOffersData = useSelector(({ hotOffers }) => hotOffers.hotOffersData);
  const hotOfferTitle = hotOffersData.title;
  const adverstiseSectionData = hotOffersData.adverstise_section;

  // For sort by filter

  const sortByOption =
    sortByOptionList &&
    sortByOptionList
      .filter((option) => option.is_visible === "1")
      .map((limit) => {
        return {
          title: limit.title,
          value: limit.sort_id,
        };
      });

  // For show per page

  const listLimitOptionList = filterOptionList.list_limit_section;

  const defaultDropdownValue =
    listLimitOptionList &&
    listLimitOptionList.find((option) => option.is_default === "1");

  const { title = "100" } = defaultDropdownValue || {};

  useEffect(() => {
    defaultDropdownValue && setPageLimit(title);
  }, [title]); // eslint-disable-line

  const listLimitOption =
    listLimitOptionList &&
    listLimitOptionList
      .filter((option) => option.is_visible === "1")
      .map((limit) => {
        return {
          title: limit.title,
          value: limit.title,
        };
      });

  const productList = useSelector(
    ({ product }) => product.productListByDepartment
  );

  const productListByDepartmentTotalPages = useSelector(
    ({ product }) => product.productListByDepartmentTotalPages
  );

  const filterOptions = useSelector(
    ({ product }) => product.filterOptions
  );

  const priceSection = useSelector(
    ({ product }) => product.filterOptionList.price_section
  );

  const updatedDepartmentTree = departmentTree
    ? departmentTree
      .filter((fld) => fld.slug === departmentSlug)
      .map((department) => {
        return {
          ...department,
          text: department.name,
        };
      })
    : [];
  const updatedOtherDepartmentTree = departmentTree
    ? departmentTree
      .filter((fld) => fld.slug !== departmentSlug)
      .map((department) => {
        return {
          ...department,
        };
      })
    : [];

  return (
    <div className="individual-listing-page">
      {departmentDetails && (
        <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={""} />
      )}
      <AdsSpaceSectionMain adsDetails={categoryadsSection} />
      <div className="container">
        <div className="individual-listing-page-wrapper">
          <div className="listing-page-wrapper-items">
            <ProductListingPageSidebar
              priceSection={priceSection}
              departmentTree={updatedDepartmentTree}
              otherDepartmentTree={updatedOtherDepartmentTree}
              iftOptionList={filterOptions}
              maxPriceValue={maxPriceValue}
              minPriceValue={minPriceValue}
              redirectBaseUrl="departments"
            />
          </div>
          
          <div className="listing-page-wrapper-items">
          {!productListByDepartmentLoading && (
            <AdsSpaceSection
              adsDetails={categoryadsSection}
              department={departmentDetails.name}
            />
            )}
            <ProductListingPageAction
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              listLimitOption={listLimitOption}
              sortByOption={sortByOption}
              productListByDepartmentTotalCount={
                productListByDepartmentTotalCount
              }
              priceSection={priceSection}
              departmentTree={updatedDepartmentTree}
              otherDepartmentTree={updatedOtherDepartmentTree}
              iftOptionList={filterOptions}
              maxPriceValue={maxPriceValue}
              minPriceValue={minPriceValue}
              redirectBaseUrl="departments"
            />
            <ProductList
              activePageNo={activePageNo}
              productList={productList}
              productListByDepartmentTotalPages={
                productListByDepartmentTotalPages
              }
            />
          </div>
        </div>
        <HotOffers
          title={hotOfferTitle}
          adverstiseSectionData={adverstiseSectionData}
        />
      </div>
    </div>
  );
};
export default IndividualDepartment;
