import {
  FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS,
  FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_SUCCESS,
  FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_FAILURE,
  FETCH_CART_LIST,
  FETCH_CART_LIST_SUCCESS,
  FETCH_CART_LIST_FAILURE,
  ADD_PRODUCT_TO_CART_LIST,
  ADD_PRODUCT_TO_CART_LIST_SUCCESS,
  ADD_PRODUCT_TO_CART_LIST_FAILURE,
  REMOVE_PRODUCT_TO_CART_LIST,
  REMOVE_PRODUCT_TO_CART_LIST_SUCCESS,
  REMOVE_PRODUCT_TO_CART_LIST_FAILURE,
  UPDATE_PRODUCT_TO_CART_LIST,
  UPDATE_PRODUCT_TO_CART_LIST_SUCCESS,
  UPDATE_PRODUCT_TO_CART_LIST_FAILURE,
  CLEAR_CART_LIST,
  CLEAR_CART_LIST_SUCCESS,
  CLEAR_CART_LIST_FAILURE,
  ADD_WISHLIST_DATA,
  ADD_WISHLIST_DATA_SUCCESS,
  ADD_WISHLIST_DATA_FAILURE,

  // catering and meal kit
  ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST,
  ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS,
  ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE,
  RESET_ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_ERROR,
  REMOVE_CATERING_PRODUCT_TO_CART_LIST,
  REMOVE_CATERING_PRODUCT_TO_CART_LIST_SUCCESS,
  REMOVE_CATERING_PRODUCT_TO_CART_LIST_FAILURE,
  CLEAR_CATERING_CART_LIST,
  CLEAR_CATERING_CART_LIST_SUCCESS,
  CLEAR_CATERING_CART_LIST_FAILURE,
  UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE,
  UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS,
  UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST,
  RESET_SELECTED_CUSTOM_OPTIONS
} from "redux/constants/actionTypes";
let initialState = {
  cartItemsCount: "0",
  cartItemsTotalAmount: "0",
  wishlistItemsCount: "0",
  cartItems: [],
  cartItemsLoading: false,
  cartItemsError: "",
  addProductToCartListLoading: false,
  addProductToCartListLoadingError: "",
  removeProductToCartList: {},
  removeProductToCartListLoading: false,
  removeProductToCartListError: "",
  productCartList: [],
  productCartDetails: {},
  productCartListLoading: false,
  productCartListError: "",
  updateProductToCartListLoading: false,
  updateProductToCartListLoadingError: "",
  clearCartListLoading: false,
  clearCartListError: "",

  wishListItemsStatus: "",
  wishListItemsLoading: false,
  wishListItemsMessage: "",

  //catering and meal kit product
  cateringAndMealKitProductAddLoading: false,
  cateringAndMealKitProductCartList: [],
  cateringAndMealKitProductAddError: "",
  removeCateringProductToCartListLoading: false,
  removeCateringProductToCartListError: "",
  clearCateringCartListLoading: false,
  cateringAndMealKitProductUpdateLoading: false,
  cateringAndMealKitProductUpdateError: "",
  selectedCateringAndMealKitProductUpdateId: ""
};

const cartWishlist = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS:
      return {
        ...state,
        cartItemsLoading: true
      };
    case FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_SUCCESS:
      return {
        ...state,
        cartItemsLoading: false,
        cartItems: action.payload.cart_item_list,
        wishlistItemsCount: action.payload.count_wishlist_items,
        cartItemsCount: action.payload.cart_items_count,
        cartItemsTotalAmount: action.payload.cart_items_amount
      };
    case FETCH_CART_WISHLIST_DETAILS_WITH_ITEMS_FAILURE:
      return {
        ...state,
        cartItemsError: action.payload.error
      };

    // fetch cart list
    case FETCH_CART_LIST:
      return {
        ...state,
        productCartListLoading: true
      };
    case FETCH_CART_LIST_SUCCESS:
      return {
        ...state,
        productCartListLoading: false,
        productCartList: action.payload || [],
        productCartDetails: action.cartDetails,
        cateringAndMealKitProductCartList:
          action.cartDetails.catering_and_mealkit_item_list
      };
    case FETCH_CART_LIST_FAILURE:
      return {
        ...state,
        productCartListError: action.payload.error
      };

    // Add to cart list

    case ADD_PRODUCT_TO_CART_LIST:
      return {
        ...state,
        addProductToCartListLoading: true
      };
    case ADD_PRODUCT_TO_CART_LIST_SUCCESS:
      return {
        ...state,
        addProductToCartListLoading: false,
        productCartList: [...state.productCartList, action.payload]
      };
    case ADD_PRODUCT_TO_CART_LIST_FAILURE:
      return {
        ...state,
        addProductToCartListLoadingError: action.payload.error
      };

    // update product to cart list

    case UPDATE_PRODUCT_TO_CART_LIST:
      return {
        ...state,
        updateProductToCartListLoading: true
      };
    case UPDATE_PRODUCT_TO_CART_LIST_SUCCESS:
      return {
        ...state,
        updateProductToCartListLoading: false,
        productCartList: state.productCartList.map((product) => {
          if (product?.item_id === action?.payload?.item_id) {
            return action?.payload;
          } else {
            return product;
          }
        })
      };
    case UPDATE_PRODUCT_TO_CART_LIST_FAILURE:
      return {
        ...state,
        updateProductToCartListLoading: false,
        updateProductToCartListLoadingError: action.payload.error
      };

    // remove cart list

    case REMOVE_PRODUCT_TO_CART_LIST:
      const cartProductList = state.productCartList;
      return {
        ...state,
        removeProductToCartListLoading: true,
        productCartList: cartProductList.filter(
          (product) => product.item_id !== action.payload
        )
      };
    case REMOVE_PRODUCT_TO_CART_LIST_SUCCESS:
      return {
        ...state,
        removeProductToCartListLoading: false
      };
    case REMOVE_PRODUCT_TO_CART_LIST_FAILURE:
      return {
        ...state,
        removeProductToCartListError: action.payload.error
      };

    // clear cart list
    case CLEAR_CART_LIST:
      return {
        ...state,
        clearCartListLoading: true
      };
    case CLEAR_CART_LIST_SUCCESS:
      return {
        ...state,
        clearCartListLoading: false,
        productCartList: []
      };
    case CLEAR_CART_LIST_FAILURE:
      return {
        ...state,
        clearCartListError: action.payload.error
      };

    // Add to wishlist
    case ADD_WISHLIST_DATA:
      return {
        ...state,
        wishListItemsLoading: true
      };
    case ADD_WISHLIST_DATA_SUCCESS:
      return {
        ...state,
        wishListItemsLoading: false,
        wishListItemsStatus: action.payload.status,
        wishListItemsMessage: action.payload.message
      };
    case ADD_WISHLIST_DATA_FAILURE:
      return {
        ...state,
        wishListItemsStatus: action.payload.status,
        wishListItemsMessage: action.payload.error
      };
    case RESET_SELECTED_CUSTOM_OPTIONS:
      return {
        ...state,
        wishListItemsStatus: "",
        wishListItemsMessage: ""
      };

    // catering and meal kit Add to cart list

    case ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST:
      return {
        ...state,
        cateringAndMealKitProductAddLoading: true,
        cateringAndMealKitProductAddError: ""
      };
    case ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS:
      return {
        ...state,
        cateringAndMealKitProductAddLoading: false,
        cateringAndMealKitProductCartList: [
          ...state.cateringAndMealKitProductCartList,
          action.payload
        ]
      };
    case ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE:
      return {
        ...state,
        cateringAndMealKitProductAddLoading: false,
        cateringAndMealKitProductAddError: action.payload.error
      };
    case RESET_ADD_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_ERROR:
      return {
        ...state,
        cateringAndMealKitProductAddError: ""
      };

    case REMOVE_CATERING_PRODUCT_TO_CART_LIST:
      return {
        ...state,
        removeCateringProductToCartListLoading: true
      };

    case REMOVE_CATERING_PRODUCT_TO_CART_LIST_SUCCESS:
      const cartCateringProductList = state.cateringAndMealKitProductCartList;
      return {
        ...state,
        removeCateringProductToCartListLoading: false,
        cateringAndMealKitProductCartList: cartCateringProductList.filter(
          (product) => product.meal_item_id !== action.payload
        )
      };
    case REMOVE_CATERING_PRODUCT_TO_CART_LIST_FAILURE:
      return {
        ...state,
        removeCateringProductToCartListError: action.payload.error
      };

    case CLEAR_CATERING_CART_LIST:
      return {
        ...state,
        clearCartListLoading: true
      };
    case CLEAR_CATERING_CART_LIST_SUCCESS:
      return {
        ...state,
        clearCartListLoading: false,
        cateringAndMealKitProductCartList: []
      };
    case CLEAR_CATERING_CART_LIST_FAILURE:
      return {
        ...state,
        clearCartListError: action.payload.error
      };
    //update catering and meal
    case UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST:
      return {
        ...state,
        cateringAndMealKitProductUpdateLoading: true,
        selectedCateringAndMealKitProductUpdateId: action.payload
      };
    case UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_SUCCESS:
      return {
        ...state,
        cateringAndMealKitProductUpdateLoading: false,
        cateringAndMealKitProductCartList:
          state.cateringAndMealKitProductCartList.map((product) => {
            if (product.meal_item_id === action.payload.meal_item_id) {
              return action.payload;
            } else {
              return product;
            }
          }),
        selectedCateringAndMealKitProductUpdateId: ""
      };
    case UPDATE_CATERING_AND_MEAL_KIT_PRODUCT_TO_CART_LIST_FAILURE:
      return {
        ...state,
        cateringAndMealKitProductUpdateLoading: action.payload.error
      };

    default:
      return state;
  }
};

export default cartWishlist;
