import React, { useState } from "react";
import "./billboardBanner.scss";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { mediaAnalyticsClick, mediaAnalyticsImpression } from "redux/actions";
import Slide from "./Slide";
import ProductBanner from 'routes/home/BannerSection/BannerSection';

const BillBoardBanner = ({ adsDetails, isMobile }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGtin, setSelectedGtin] = useState(null);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleOpen = (gtins) => {
    setSelectedGtin(gtins);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedGtin(null);
  };

  return (
    <>
      <div className="billboard-banner-section">
        <div className="container">
          <Slider {...settings}>
            {adsDetails.map((el) => (
              <Slide
                key={el.citrusAdId}
                el={el}
                onOpenModal={handleOpen}
                onCloseModal={handleClose}
                isModalOpen={isModalOpen}
                selectedGtin={selectedGtin}
                isMobile={isMobile}
              />
            ))}
          </Slider>
        </div>
      </div>
      {isModalOpen && (
        <ProductBanner setIsModalOpen={handleClose} show={isModalOpen} gtins={selectedGtin} />
      )}
    </>
  );
};

export default BillBoardBanner;
