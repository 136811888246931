import React from "react";
import "./multibannerad.scss";
import MultiBannerAdSlide from "./MultiBannerAdSlide";

const MultiBannerAd = ({data}) => {
  return (
    <>
      {data && data.length > 0 && (
        <div className="multi-banner-theme2">
          <div className="container">
            <div className="multi-img-wrap">
              {data
                ? data.map((el) => {
                    return (
                      <MultiBannerAdSlide el={el}/>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MultiBannerAd;
