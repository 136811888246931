// actions created for b2b form [24-06-2024] by kiran konda
import {
  PHONESUBSCRIPTION_START,
  PHONESUBSCRIPTION_SUCCESS,
  PHONESUBSCRIPTION_FAILURE
} from "redux/constants/actionTypes";

import axios from "config/axios";

export const phoneSubScriptionFormDetails = (payload) => {
  return {
    type: PHONESUBSCRIPTION_START,
    payload: payload
  };
};
export const phoneSubScriptionFormSuccess = (payload) => {
  return {
    type: PHONESUBSCRIPTION_SUCCESS,
    payload: payload
  };
};
export const phoneSubScriptionFormFailure = (payload) => {
  return {
    type: PHONESUBSCRIPTION_FAILURE,
    payload: payload
  };
};

export const phoneSubScriptionForm = (body) => async (dispatch) => {
  dispatch(phoneSubScriptionFormDetails());
  axios
    .post("/AogPhoneSubScriptionForm", body)
    .then((res) => {
      dispatch(phoneSubScriptionFormSuccess(res?.data));
    })
    .catch((error) => {
      dispatch(phoneSubScriptionFormFailure({ error: error?.data?.message }));
    });
};
