import {
  ADD_CUSTOMER_ADDRESS,
  ADD_CUSTOMER_ADDRESS_SUCCESS,
  ADD_CUSTOMER_ADDRESS_FAILURE,
  FETCH_CUSTOMER_ADDRESS_LIST,
  FETCH_CUSTOMER_ADDRESS_LIST_SUCCESS,
  FETCH_CUSTOMER_ADDRESS_LIST_FAILURE,
  SELECT_CHECKOUT_STORE_TYPE,
  SELECT_CHECKOUT_PAYMENT_METHOD,
  FETCH_STORE_TIME_SLOTS,
  FETCH_STORE_TIME_SLOTS_SUCCESS,
  FETCH_STORE_TIME_SLOTS_FAILURE,
  FETCH_CART_AMOUNT_DETAILS,
  FETCH_CART_AMOUNT_DETAILS_SUCCESS,
  FETCH_CART_AMOUNT_DETAILS_FAILURE,
  SELECT_CHECKOUT_TIME_SLOT,
  SELECT_CUSTOMER_ADDRESS,
  SAVE_PLACE_ORDER,
  SAVE_PLACE_ORDER_SUCCESS,
  SAVE_PLACE_ORDER_FAILURE,
  CHECKOUT_SPECIAL_INSTRUCTION,
  CHECKOUT_ORDER_PERFERRED_CONTACT_TYPE,
  CHECKOUT_ORDER_PERFERRED_CONTACT,

  REMOVE_CUSTOMER_ADDRESS,
  REMOVE_CUSTOMER_ADDRESS_FAILURE,
  REMOVE_CUSTOMER_ADDRESS_SUCCESS,
  SELECT_PICKUP_TYPE,

  SAVE_PLACE_ORDER_CATERING_MEAL,
  SAVE_PLACE_ORDER_CATERING_MEAL_SUCCESS,
  SAVE_PLACE_ORDER_CATERING_MEAL_FAILURE,
  SAVE_EBT_PAYMENT_RESPONSE,
  SAVE_EBT_PAYMENT_PAID_LIST,

  CANCEL_EBT_PAYMENT,
  CANCEL_EBT_PAYMENT_SUCCESS,
  CANCEL_EBT_PAYMENT_FAILURE,

  RESET_EBT_CHECKOUT_STATE,
  FETCH_ORDER_DETAIL,
  FETCH_ORDER_DETAIL_SUCCESS,
  FETCH_ORDER_DETAIL_FAILURE,
  SAVE_PAYMENT_TRANSACTION_ID,
  SAVE_PAYMENT_TRANSACTION_TYPE,
  EXTERNAL_DELIVERY_ID,

  FETCH_CATERING_AND_MEALKIT_CART_AMOUNT_DETAILS,
  FETCH_CATERING_AND_MEALKIT_CART_AMOUNT_DETAILS_SUCCESS,
  FETCH_CATERING_AND_MEALKIT_CART_AMOUNT_DETAILS_FAILURE
} from "redux/constants/actionTypes";

let initialState = {
  customerAddress: null,
  customerAddressLoading: false,
  customerAddressError: "",
  customerAddressList: [],
  customerAddressListLoading: false,
  customerAddressListError: "",
  checkoutStoreType: "",
  checkoutPaymentMethod: "",
  storeTimeSlots: null,
  storeTimeSlotsLoading: false,
  storeTimeSlotsError: "",
  cartAmountDetails: {},
  cartAmountDetailsLoading: false,
  cartAmountDetailsError: "",
  checkoutTimeSlot: "",
  savePlaceOrder: {},
  savePlaceOrderLoading: false,
  savePlaceOrderError: "",
  savePlaceOrderCateringAndMeal: {},
  savePlaceOrderCateringAndMealLoading: false,
  savePlaceOrderCateringAndMealError: "",
  checkoutInstruction: "",
  checkoutContactType: "call_me",
  checkoutContact: "",


  removeAddressLoading: true,
  removeAddressError: false,
  removeAddressStatus: '',

  checkoutPickupType: 'later',
  ebtPaymentResponse: null,
  isFollowedEbtPayment: false,
  ebtPaymentPaidList: [],

  cancelPaidEbtEntryLoading: false,

  fetOrderDetailLoading: false,
  orderDetail: null,
  fetOrderDetailError: "",

  paymentTransactionId: "",
  paymentTransactionType: "",
  externalDeliveryId: "",

  cateringAndMealkitCartAmountDetails: {},
  cateringAndMealkitCartAmountDetailsLoading: false,
  cateringAndMealkitCartAmountDetailsError: "",
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    // add customer address
    case ADD_CUSTOMER_ADDRESS:
      return {
        ...state,
        customerAddressLoading: true,
      };
    case ADD_CUSTOMER_ADDRESS_SUCCESS:

      const updatedAddressList = state.customerAddressList.map((address) => {
        return {
          ...address,
          is_primary: "0",
        };
      })
      return {
        ...state,
        customerAddressLoading: false,
        customerAddressList: [...updatedAddressList, action.payload]
      };
    case ADD_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        customerAddressLoading: false,
        customerAddressError: action.payload.error,
      };

    // customer address list
    case FETCH_CUSTOMER_ADDRESS_LIST:

      return {
        ...state,
        customerAddressListLoading: true,
      };
    case FETCH_CUSTOMER_ADDRESS_LIST_SUCCESS:

      return {
        ...state,
        customerAddressListLoading: false,
        customerAddressList: action.payload ? action.payload : [],
      };
    case FETCH_CUSTOMER_ADDRESS_LIST_FAILURE:
      return {
        ...state,
        customerAddressListError: action.payload.error,
      };

    // Store time slots
    case FETCH_STORE_TIME_SLOTS:
      return {
        ...state,
        storeTimeSlotsLoading: true,
      };
    case FETCH_STORE_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        storeTimeSlotsLoading: false,
        storeTimeSlots: action.payload,
      };
    case FETCH_STORE_TIME_SLOTS_FAILURE:
      return {
        ...state,
        storeTimeSlotsError: action.payload.error,
      };

    // Cart amount
    case FETCH_CART_AMOUNT_DETAILS:
      return {
        ...state,
        cartAmountDetailsLoading: true,
      };
    case FETCH_CART_AMOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        cartAmountDetailsLoading: false,
        cartAmountDetails: action.payload,
      };
    case FETCH_CART_AMOUNT_DETAILS_FAILURE:
      return {
        ...state,
        cartAmountDetailsError: action.payload.error,
      };

    // Save order
    case SAVE_PLACE_ORDER:
      return {
        ...state,
        savePlaceOrderLoading: true,
        savePlaceOrderError: ''
      };
    case SAVE_PLACE_ORDER_SUCCESS:
      return {
        ...state,
        savePlaceOrderLoading: false,
        savePlaceOrder: action.payload,
      };
    case SAVE_PLACE_ORDER_FAILURE:
      return {
        ...state,
        savePlaceOrderLoading: false,
        savePlaceOrderError: action.payload.error,
      };

    // Save order catering and meals
    case SAVE_PLACE_ORDER_CATERING_MEAL:
      return {
        ...state,
        savePlaceOrderCateringAndMealLoading: true,
      };
    case SAVE_PLACE_ORDER_CATERING_MEAL_SUCCESS:
      return {
        ...state,
        savePlaceOrderCateringAndMealLoading: false,
        savePlaceOrder: action.payload,
      };
    case SAVE_PLACE_ORDER_CATERING_MEAL_FAILURE:
      return {
        ...state,
        savePlaceOrderCateringAndMealError: action.payload.error,
      };

    case SELECT_CHECKOUT_STORE_TYPE:
      return {
        ...state,
        checkoutStoreType: action.payload,
      };
    case SELECT_PICKUP_TYPE:
      return {
        ...state,
        checkoutPickupType: action.payload,
      };

    case SELECT_CHECKOUT_PAYMENT_METHOD:
      return {
        ...state,
        checkoutPaymentMethod: action.payload,
      };
    case SELECT_CHECKOUT_TIME_SLOT:
      return {
        ...state,
        checkoutTimeSlot: action.payload,
      };
    case SELECT_CUSTOMER_ADDRESS:
      return {
        ...state,
        customerAddress: action.payload,
      };
    case CHECKOUT_SPECIAL_INSTRUCTION:
      return {
        ...state,
        checkoutInstruction: action.payload,
      };
    case CHECKOUT_ORDER_PERFERRED_CONTACT_TYPE:
      return {
        ...state,
        checkoutContactType: action.payload,
      };
    case CHECKOUT_ORDER_PERFERRED_CONTACT:
      return {
        ...state,
        checkoutContact: action.payload + "",
      };

    //Adress Remove
    case REMOVE_CUSTOMER_ADDRESS:
      return {
        ...state,
        removeAddressLoading: true,
      };
    case REMOVE_CUSTOMER_ADDRESS_SUCCESS:
      return {
        ...state,
        removeAddressLoading: false,
        removeAddressStatus: action.payload.status,
      };
    case REMOVE_CUSTOMER_ADDRESS_FAILURE:
      return {
        ...state,
        removeAddressError: action.payload.error,
      };
    case SAVE_EBT_PAYMENT_RESPONSE:
      return {
        ...state,
        ebtPaymentResponse: action.payload,
        isFollowedEbtPayment: action.payload ? true : false
      };
    case SAVE_EBT_PAYMENT_PAID_LIST:
      return {
        ...state,
        ebtPaymentPaidList: action.payload,
      };

    case CANCEL_EBT_PAYMENT:
      return {
        ...state,
        cancelPaidEbtEntryLoading: true,
      };
    case CANCEL_EBT_PAYMENT_SUCCESS:
      return {
        ...state,
        ebtPaymentPaidList: [],
        isFollowedEbtPayment: false,
        cancelPaidEbtEntryLoading: false,
      };

    case RESET_EBT_CHECKOUT_STATE:
      return {
        ...state,
        ebtPaymentPaidList: [],
        isFollowedEbtPayment: false,
        cancelPaidEbtEntryLoading: false,
        ebtPaymentResponse: null
      };

    case CANCEL_EBT_PAYMENT_FAILURE:
      return {
        ...state,
        cancelPaidEbtEntryLoading: false,
        cancelPaidEbtEntryError: action.payload.error,
      };

    case FETCH_ORDER_DETAIL:
      return {
        ...state,
        fetOrderDetailLoading: true,
      };

    case FETCH_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        fetOrderDetailLoading: false,
        isFollowedEbtPayment: !!action.payload.paid_list.length,
        savePlaceOrder: action.payload.order_details,
        ebtPaymentPaidList: action.payload.paid_list
      };

    case FETCH_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        fetOrderDetailLoading: false,
        fetOrderDetailError: action.payload.error,
      };

    case SAVE_PAYMENT_TRANSACTION_ID:
      return {
        ...state,
        paymentTransactionId: action.payload
      };
    case SAVE_PAYMENT_TRANSACTION_TYPE:
      return {
        ...state,
        paymentTransactionType: action.payload
      };
    case EXTERNAL_DELIVERY_ID:
      return {
        ...state,
        externalDeliveryId: action.payload,
      };

    // Cart amount
    case FETCH_CATERING_AND_MEALKIT_CART_AMOUNT_DETAILS:
      return {
        ...state,
        cateringAndMealkitCartAmountDetailsLoading: true,
      };
    case FETCH_CATERING_AND_MEALKIT_CART_AMOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        cateringAndMealkitCartAmountDetailsLoading: false,
        cateringAndMealkitCartAmountDetails: action.payload,
      };
    case FETCH_CATERING_AND_MEALKIT_CART_AMOUNT_DETAILS_FAILURE:
      return {
        ...state,
        cateringAndMealkitCartAmountDetailsError: action.payload.error,
      };
    default:
      return state;
  }
};

export default checkoutReducer;
