import Slider from "react-slick/lib/slider";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import ProductCardTheme2 from "components/ProductCardTheme2";
import "./product-list-slider-theme2.scss";

const ProductListSliderTheme2 = ({ products, addButtonText }) => {
  
  const settings = {
    dots: false,
    infinite: products.length > 5,
    // slidesToShow: products.length > 1 ? 5 : products.length,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          infinite: false,
        },
      },
    ],
  };
  return (
    <div className="container">
      <div className="product-list-wrapper">
        <Slider {...settings}>
          {products.map((product) => {
            return <ProductCardTheme2 product={product} key={product.product_id} addButtonText={addButtonText} />;
          })}
        </Slider>
      </div>
    </div>
  );
};
export default ProductListSliderTheme2;
