import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { customerAddress } from "redux/actions";
import "./address-section.scss";
import { getLocalStorageValue } from "config/helper";

const AddressSection = ({ isAddressList }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");

  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const { customerAddressLoading } = useSelector(({ checkout }) => checkout);

  const handleSubmitAddressForm = (data) => {
    const {
      firstName,
      lastName,
      zipCode,
      phoneNo,
      address1,
      address2,
      city,
      state,
    } = data;

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: memberNumber,
      address_1: address1,
      address_2: address2,
      contact: phoneNo,
      city: city,
      state: state,
      zip_code: zipCode,
      status: "shipping",
      is_primary: "1",
      user_token: userToken,
      DeviceType: "web",
      Version: "1",
      first_name: firstName,
      last_name: lastName,
      AppName: "shop",
    };
    dispatch(customerAddress(body)).then(() => isAddressList());
  };

  return (
    <>
      <div className="address-section">
        <h1>Choose a delivery address</h1>
        <form
          name="register"
          onSubmit={handleSubmit(handleSubmitAddressForm)}
          className="address-form"
        >
          <div className="input-name-grid">
            <div className="form-control">
              <label>First Name*</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                ref={register({
                  required: true,
                  pattern: /^[A-Za-z]+$/,
                })}
              />
              {errors.firstName && errors.firstName.type === "required" && (
                <span>Please enter your First Name</span>
              )}
              {errors.firstName && errors.firstName.type === "pattern" && (
                <span>Please enter valid First Name</span>
              )}
            </div>
            <div className="form-control">
              <label>Last Name*</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                ref={register({
                  required: true,
                  pattern: /^[A-Za-z]+$/,
                })}
              />
              {errors.lastName && errors.lastName.type === "required" && (
                <span>Please enter your Last Name</span>
              )}
              {errors.lastName && errors.lastName.type === "pattern" && (
                <span>Please enter valid Last Name</span>
              )}
            </div>
          </div>
          <div className="form-control">
            <label>Phone*</label>
            <input
              type="text"
              name="phoneNo"
              id="phoneNo"
              ref={register({
                required: true,
                pattern: /^[0-9]{10}$/,
              })}
            />

            {errors.phoneNo && errors.phoneNo.type === "required" && (
              <span>Please enter phone no.</span>
            )}
            {errors.phoneNo && errors.phoneNo.type === "pattern" && (
              <span>Please enter valid phone no.</span>
            )}
          </div>
          <div className="input-name-grid">
            <div className="form-control">
              <label>Address*</label>
              <input
                type="text"
                name="address1"
                id="address1"
                ref={register({
                  required: true,
                })}
              />
            </div>
            <div className="form-control">
              <div className="addres-sec-line">
                <label>Address 2*</label>
                <input
                  type="text"
                  name="address2"
                  id="address2"
                  ref={register({
                    required: false,
                  })}
                />
                {errors.address1 && errors.address1.type === "required" && (
                  <span>Please enter your address.</span>
                )}
              </div>
            </div>
          </div>
          <div className="input-name-grid3">
            <div className="form-control">
              <label>City*</label>
              <input
                type="text"
                name="city"
                id="city"
                ref={register({
                  required: true,
                })}
              />
              {errors.city && errors.city.type === "required" && (
                <span>Please enter your city.</span>
              )}
            </div>
            <div className="form-control">
              <label>Zip*</label>
              <input
                name="zipCode"
                id="zipCode"
                type="text"
                ref={register({
                  required: true,
                  pattern: /^[0-9]{5}$/,
                })}
              />

              {errors.zipCode && errors.zipCode.type === "required" && (
                <span>Please enter your Zip Code</span>
              )}
              {errors.zipCode && errors.zipCode.type === "pattern" && (
                <span>Entered zip code is not valid</span>
              )}
            </div>
            <div className="form-control">
              <label>State*</label>
              <input
                type="text"
                name="state"
                id="state"
                ref={register({
                  required: true,
                })}
              />
              {errors.state && errors.state.type === "required" && (
                <span>Please enter your state.</span>
              )}
            </div>
          </div>
          <div
            className={`address-submit-btn ${
              customerAddressLoading ? "disabled" : ""
            }`}
          >
            <button type="submit">
              {customerAddressLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddressSection;
