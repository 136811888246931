import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import ProductList from "components/ProductList";
import ProductListingPageSidebar from "components/ProductListingPage/Sidebar";
import ProductListingPageSidebarTheme2 from "components/ProductListingPageTheme2/SidebarTheme2";
import ProductListingPageAction from "components/ProductListingPage/Actions";
import ProductListingPageActionTheme2 from "components/ProductListingPageTheme2/ActionsTheme2";
import Loader from "components/Loader";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";
import {
  getHotOffersData,
  getFilterOptionList,
  getProductListByDepartment,
  getDepartmentTree,
  getSearchDepartmentTree,
  updateIftCheckList,
  updateSearchText,
  WeeklySalesSelected,
  getBannerAdsData,
} from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import "./products.scss";

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { departmentSlug } = useParams();
  const [searchParams] = useSearchParams();
  const paramData = useLocation();

  const {
    currentStoreID,
    product: {
      sortByValue,
      iftCheckedList,
      filterOptionList,
      minPriceValue,
      maxPriceValue,
      departmentTree,
      searchDepartmentTree,
      nodeFunctionalText,
      nodeFunctionalLevel,
      productListByDepartmentTotalCount,
      productListByDepartment,
      productListByDepartmentLoading,
      filterOptions,
      filterOptionList: { price_section },
      memoryToken,
    },
    search: { searchText },
    user: { isUserLoggedIn },
    common: {
      commonDetailsData: {
        ecom_section: { allow_ecommerce = "" },
        header_section: { theme_type }
      },
    },
    bannerAds: { bannerAdsData, bannerAdsDataLoading },
  } = useSelector((state) => state);

  const  activePageNo = useSelector(({ product }) => product.activePageNo);
  const productListByDepartmentTotalPages = useSelector(({ product }) => product.productListByDepartmentTotalPages);
  const ecomData = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section.sub_department
  );
  let urlParams = new URLSearchParams(window.location.search);
  let searchValue = urlParams.get("s");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const [ready, setReady] = useState(false);
  const [couponConditionChange, setCouponConditionChange] = useState(true);
  const [pageLimit, setPageLimit] = useState("");
  const function_names = searchParams.get("function_names");
  const iftCheckedListJoin = iftCheckedList.join("|");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [filteredBanners, setFilteredBanners] = useState([]);
  const breadcrumbList = useMemo(() => [{ lable: "Shop", link: "", searchTag: "" }], []);
  const couponCondition = useMemo(() => paramData?.state !== null && (iftCheckedList?.length === 0) & couponConditionChange, [paramData, iftCheckedList, couponConditionChange]);

  const sortByOptionList = filterOptionList.sort_by_section;
  const selectedSortByOption = sortByOptionList?.find((option) => option.sort_id === sortByValue);
  const sortBy = selectedSortByOption?.field_name;

  const defaultDropdownValue = filterOptionList.list_limit_section?.find((option) => option.is_default === "1");
  const { title = "100" } = defaultDropdownValue || {};

  const departmentDetails = useMemo(() => ecomData?.find((ecom) => ecom.slug === departmentSlug), [ecomData, departmentSlug]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true);
    }, 10);
    return () => clearTimeout(timer);
  }, [clientId, storeId, activePageNo, departmentDetails, iftCheckedListJoin, pageLimit, sortBy, minPriceValue, maxPriceValue, nodeFunctionalText, nodeFunctionalLevel, function_names, isUserLoggedIn, memberNumber, userToken, searchText, dispatch]);
  
  useEffect(() => {
    if (ready) {
      getProductListDeptAPICall();
      setReady(false);
    }
  }, [ready]);

  const getProductListDeptAPICall = () => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      PageNo: activePageNo,
      category_ids: departmentDetails?.category_ids || "",
      category_name: departmentDetails?.name || "",
      ift: iftCheckedListJoin,
      limit: pageLimit,
      sort_by: sortBy || "",
      min_price: minPriceValue.toString(),
      max_price: maxPriceValue.toString(),
      funtional_text: nodeFunctionalText,
      funtional_level: nodeFunctionalLevel,
      function_names: function_names || "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      search: searchText || "",
      memoryToken: activePageNo > 1 ? memoryToken : "",
    };
    dispatch(getProductListByDepartment(body));
  }; 

  useEffect(() => {
    if (allow_ecommerce === "0") navigate("/coupons");
  }, [allow_ecommerce, navigate]);

  const fetchData = useCallback((action, additionalBody = {}) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      ...additionalBody,
    };
    dispatch(action(body));
  }, [clientId, storeId, isUserLoggedIn, memberNumber, userToken, dispatch]);

  useEffect(() => {
    fetchData(getFilterOptionList);
    fetchData(getHotOffersData);
  }, [storeId, isUserLoggedIn, clientId, memberNumber, userToken, fetchData]);

  useEffect(() => {
    dispatch(updateSearchText(searchValue));
    dispatch(WeeklySalesSelected(false));
  }, [dispatch, searchValue]);


  useEffect(() => {
    if (couponCondition) {
      dispatch(updateIftCheckList(paramData?.state?.coupondata));
    }
  }, [couponCondition, dispatch, paramData?.state?.coupondata]);

  useEffect(() => {
    fetchData(getSearchDepartmentTree, { search: searchText });
  }, [searchText, fetchData]);

  useEffect(() => {
    if (!departmentTree.length) {
      fetchData(getDepartmentTree, { search: "" });
    }
  }, [departmentTree.length, fetchData]);

  useEffect(() => {
    fetchData(getBannerAdsData, {
      search: searchText,
      category_ids: "",
      funtional_text: "",
      placement: "search-banner-x",
    });
  }, [searchText, fetchData]);

  useEffect(() => {
    if (defaultDropdownValue) {
      setPageLimit(title);
    }
  }, [title, defaultDropdownValue]);

  const updatedDepartmentTree = useMemo(() => searchDepartmentTree?.map((department) => ({ ...department, text: department.name })), [searchDepartmentTree]);
  const updatedOtherDepartmentTree = useMemo(() => departmentTree?.filter((department) => !searchDepartmentTree.map((searchDepartment) => searchDepartment.category_ids).includes(department.category_ids)), [departmentTree, searchDepartmentTree]);

  const sortByOption = useMemo(() => sortByOptionList?.filter((option) => option.is_visible === "1").map((limit) => ({ title: limit.title, value: limit.sort_id })), [sortByOptionList]);
  const listLimitOption = useMemo(() => filterOptionList.list_limit_section?.filter((option) => option.is_visible === "1").map((limit) => ({ title: limit.title, value: limit.title })), [filterOptionList.list_limit_section]);
  const checkViewportWidth = useCallback(() => {
    setIsMobile(window.innerWidth < 769);
  }, []);

  useEffect(() => {
    checkViewportWidth();
    window.addEventListener('resize', checkViewportWidth);
    return () => {
      window.removeEventListener('resize', checkViewportWidth);
    };
  }, [checkViewportWidth]);

  useEffect(() => {
    setFilteredBanners(bannerAdsData?.ads?.filter(ad => ad.slotId === "Horizontal Banners")[0]?.banners ?? []);
  }, [isMobile, bannerAdsData]);

  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Shop" />
      <div className="container">
        {!productListByDepartmentLoading && !bannerAdsDataLoading && filteredBanners[0] && (
        <AdsSpaceSectionMainTheme2 adsDetails={filteredBanners[0]}  isMobile={isMobile}/>
      )}
        <div className="listing-page-wrapper">
          <div className="listing-page-wrapper-items">
            {theme_type === "1" ? (
              <ProductListingPageSidebar
                paramData={paramData}
                setCouponConditionChange={setCouponConditionChange}
                couponCondition={couponCondition}
                departmentTree={updatedDepartmentTree}
                priceSection={price_section}
                otherDepartmentTree={updatedOtherDepartmentTree}
                iftOptionList={filterOptions}
                maxPriceValue={maxPriceValue}
                minPriceValue={minPriceValue}
                redirectBaseUrl="departments"
              />
            ) : (
              <ProductListingPageSidebarTheme2
                paramData={paramData}
                setCouponConditionChange={setCouponConditionChange}
                couponCondition={couponCondition}
                departmentTree={updatedDepartmentTree}
                priceSection={price_section}
                otherDepartmentTree={updatedOtherDepartmentTree}
                iftOptionList={filterOptions}
                maxPriceValue={maxPriceValue}
                minPriceValue={minPriceValue}
                sortByOption={sortByOption}
                listLimitOption={listLimitOption}
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                redirectBaseUrl="departments"
                getProductListDeptAPICall={getProductListDeptAPICall}
              />
            )}
          </div>
          <div className="listing-page-wrapper-items">
            {theme_type === "1" ? (
              <ProductListingPageAction
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                listLimitOption={listLimitOption}
                sortByOption={sortByOption}
                productListByDepartmentTotalCount={productListByDepartmentTotalCount}
                redirectBaseUrl="departments"
              />
            ) : (
              <ProductListingPageActionTheme2
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                listLimitOption={listLimitOption}
                sortByOption={sortByOption}
                productListByDepartmentTotalCount={productListByDepartmentTotalCount}
                priceSection={price_section}
                departmentTree={updatedDepartmentTree}
                otherDepartmentTree={updatedOtherDepartmentTree}
                iftOptionList={filterOptions}
                maxPriceValue={maxPriceValue}
                minPriceValue={minPriceValue}
                redirectBaseUrl="departments"
              />
            )}
            {!productListByDepartmentLoading && !bannerAdsDataLoading ? (
              <ProductList
                activePageNo={activePageNo}
                productList={productListByDepartment}
                productListByDepartmentTotalPages={productListByDepartmentTotalPages}
                bannerData={filteredBanners[1] ?? {}}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;