import React, { useCallback, useEffect, useState, useMemo } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import AdsSection from "./AdsSection";
import { getBannerAdsData, getWeeklyad, resetSearch } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';
import "./weekly-ads.scss";
import AdsSectionTheme2 from "./AdsSectionTheme2";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";

const WeeklyAds = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const {
    isUserLoggedIn,
    storeListData,
    currentStoreID,
    theme_type,
    weeklyadData: { data: weeklyAdsData, loading: weeklyadDataLoading, ads_section: adsSection },
    bannerAdsData: { ads: bannerAdsData, loading: bannerAdsDataLoading }
  } = useSelector(({ user, store, common, weeklyad, bannerAds }) => ({
    isUserLoggedIn: user.isUserLoggedIn,
    storeListData: store.storeListData,
    currentStoreID: store.currentStoreID,
    theme_type: common.commonDetailsData.header_section?.theme_type || {},
    weeklyadData: weeklyad.weeklyadData,
    bannerAdsData: bannerAds.bannerAdsData
  }));

  const selectedStore = useMemo(() => (
    storeListData?.find(store => store.ClientStoreId === (currentStoreID || storeId))
  ), [storeListData, currentStoreID, storeId]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [filteredBanners, setFilteredBanners] = useState([]);

  const checkViewportWidth = useCallback(() => {
    setIsMobile(window.innerWidth < 769);
  }, []);

  useEffect(() => {
    checkViewportWidth();
    window.addEventListener('resize', checkViewportWidth);
    return () => {
      window.removeEventListener('resize', checkViewportWidth);
    };
  }, [checkViewportWidth]);

  useEffect(() => {
    setFilteredBanners(bannerAdsData?.filter(ad => ad.slotId === "Horizontal Banners")[0]?.banners ?? []);
  }, [isMobile, bannerAdsData]);

  useEffect(() => {
    dispatch(resetSearch());
    const commonBody = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
      member_number: isUserLoggedIn ? memberNumber : "",
    };
    dispatch(getWeeklyad({
      ...commonBody,
      AppName: isUserLoggedIn ? "shop" : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      StoreName: selectedStore?.ClientStoreName
    }));
    dispatch(getBannerAdsData({
      ...commonBody,
      category_ids: "",
      search: "",
      funtional_text: "",
      placement: "run-of-site-banner-x",
      ros:"Weekly Ad"
    }));
  }, [dispatch, clientId, storeId, isUserLoggedIn, memberNumber, userToken, selectedStore]);

  const breadcrumbList = useMemo(() => [{ lable: "Weekly Ads", link: "", searchTag: "" }], []);

  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Weekly Ads" />
      {!weeklyadDataLoading && !bannerAdsDataLoading && filteredBanners[0] && (
        <AdsSpaceSectionMainTheme2 isMobile={isMobile} adsDetails={filteredBanners[0]} />
      )}
      {!weeklyadDataLoading && weeklyAdsData?.length > 0 && (
        theme_type === "1" ? 
          <AdsSection weeklyAdsDetails={weeklyAdsData} />
          :
          <AdsSectionTheme2 weeklyAdsDetails={weeklyAdsData} adsSectionDetails={adsSection} />
      )}
    </>
  );
};

export default WeeklyAds;
