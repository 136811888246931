import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getList, saveList, makeListActive, mediaAnalyticsClick, mediaAnalyticsImpression } from "redux/actions";
import useOnClickOutside from "hooks/useOnClickOutside";
import { getLocalStorageValue } from 'config/helper';
import "./add-to-my-list-theme-2.scss";
import { useNavigate } from "react-router-dom";

const AddToMyListTheme2 = ({
  isShowMyListDropdown,
  isShowNewListModal,
  setShowNewListModal,
  setShowMyListDropdown,
  title,
  isShowIcon,
  handleApply,
  disabled,
  loading,
  rsaMedaiId
}) => {

  const refDropdown = useRef();
  const refDropdownSecond = useRef();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [listText, setListText] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const memberNumber = getLocalStorageValue("member-number");
  const userToken = getLocalStorageValue("user-token");

  const customerLists = useSelector(
    ({ customlist }) => customlist.customerList
  );
  useOnClickOutside(refDropdown, () => setShowNewListModal(false));
  useOnClickOutside(refDropdownSecond, () => setShowMyListDropdown(false));
  const commonDetailsData = useSelector(
    ({ common }) => common.commonDetailsData
  );
  const AogAddYourListApiUrl = commonDetailsData.header_section.AogApiURL;
  useEffect(() => {
    if (customerLists) {
      const activeList = customerLists.find((item) => {
        return item.status === "1";
      });
      !activeItem && activeList && setActiveItem(activeList.list_id);
    }
  }, [customerLists]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    isShowMyListDropdown && dispatch(getList(body));
  }, [isShowMyListDropdown]); // eslint-disable-line

  const addItems = (data) => {
    if (!isUserLoggedIn) {
      navigator('/login');
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      list_name: data,
      image: "",
      AogAddYourListApiUrl: AogAddYourListApiUrl,
    };
    dispatch(saveList(body));
  };

  const handleListStatus = (list_id) => {
    setActiveItem(list_id);
    const activeList = customerLists.find((item) => {
      return item.status === "1";
    });
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      list_id,
      member_number: memberNumber,
      user_token: userToken,
      AppName: "shop",
      DeviceType: "web",
      Version: "1",
    };

    !activeList && dispatch(makeListActive(body));
  };

  return (
    <>
      <div>
        <div className={`offer-product-list-icon ${disabled ? "disabled" : ""}`} ref={refDropdownSecond}>
          <span className="tag">{rsaMedaiId !== "" ? "promoted" : ""}</span>
          <div className="add-to-list-icon" onClick={() => setShowMyListDropdown(!isShowMyListDropdown)}>
            <span className="list-tooltip">Add to list</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="36px" viewBox="0 0 24 24"><path fill="currentColor" d="M2 19.004h2.004V17H2zM7 19h15v-2H7zm-5-5.996h2.004V11H2zM7 13h15v-2H7zM2 7.004h2.004V5H2zM7 7h15V5H7z" /></svg>
          </div>
          <div className={`add-to-list-theme2-dropdown ${isShowMyListDropdown ? 'add-to-list-theme2-show' : 'add-to-list-theme2-hidden'}`}>
            <div className="add-list-theme2-dropdown-design">
              <div className="new-list-theme2-title">
                <p onClick={() => setShowNewListModal(!isShowNewListModal)}>New List</p>
                <div className={`new-list-input-theme2-dropdown ${isShowNewListModal ? 'add-to-list-theme2-show' : 'add-to-list-theme2-hidden'}`}>
                  <div className="new-list-input-list2-style" ref={refDropdown}>
                    <div className="form-control">
                      <label>Enter List Name</label>
                      <input type="text" onChange={(e) => setListText(e.target.value)} />
                    </div>
                    <div className="save-number" onClick={() => setShowNewListModal(false)}>
                      <span onClick={() => addItems(listText)}>Save</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dropdown-theme2-body">
                {customerLists && customerLists.length
                  ? customerLists.map((item) => {
                    return (
                      <div className="relative-checkbox" key={item.list_id}>
                        <input
                          type="checkbox"
                          id={item.list_name}
                          onClick={() => handleListStatus(item.list_id)}
                          checked={item.list_id === activeItem ? true : false}
                          defaultChecked={activeItem}
                        />
                        <label htmlFor={item.list_name}>
                          <span>{item.list_name}</span>
                        </label>
                      </div>
                    );
                  })
                  : "No list found."}

                {loading ? (
                  <div className="apply-text-alignment">
                    <p>Adding.. </p>
                  </div>
                ) : (
                  customerLists &&
                  !!customerLists.length && (
                    <div className="apply-text-alignment">
                      <p onClick={() => handleApply(activeItem)}>
                        Add to List{" "}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddToMyListTheme2;
