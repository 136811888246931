import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Pagination from "components/Common/Pagination";
import { updateActivePage } from "redux/actions";
import "./catering-product-list.scss";
import CateringProductCard from "../CateringProductCard";

const CateringProductList = ({
  productList,
  activePageNo,
  productListByDepartmentTotalPages,
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (activePageNo === 1) {
      dispatch(updateActivePage(1));
      setPage(1);
    }
  }, [dispatch, activePageNo]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleSetPage = (data) => {
    dispatch(updateActivePage(data));
    scrollToTop();
    setPage(data);
  };

  return (
    <div>
      <div className="catering-product-list-grid">
        {productList &&
          productList.map((product) => {
            return (
              <div
                className="catering-product-list-grid-items"
                key={product.product_id}
              >
                <CateringProductCard product={product} />
              </div>
            );
          })}
      </div>
      {productList.length ? (
        <Pagination
          pages={productListByDepartmentTotalPages}
          current={page}
          onClick={handleSetPage}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default CateringProductList;
