import React, { useEffect, useState } from "react";
import "./constant-contact.scss";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useSelector } from "react-redux";
import { constantcontactDetail } from "../../redux/actions/constantcontact";
import { useDispatch } from "react-redux";
import { getLocalStorageValue } from "config/helper";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const ConstantContact = () => {
  // From changes by kirankonda [Keyur] 02/07/2024
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const clientId = getLocalStorageValue("RSAclient-id");
  const { storeListData, currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const location = useLocation();
  const commonDetailsData = useSelector(
    ({ common }) => common.commonDetailsData
  );
  const { constantContactData, constantContactLoading, constantContactError } =
    useSelector((constantContact) => constantContact?.constantContactReducer);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const breadcrumbList = [
    { lable: "Subscriber to White's Perks", link: "", searchTag: "" }
  ];

  const { your_recaptcha_site_key } = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );
  // state for capturing input values
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    zipcode: "",
    store: ""
  });

  // state for capturing errors
  const [formError, setFormError] = useState({
    email: "",
    phone_number: ""
  });
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  // onChange function
  const formHandling = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      if (value === "") {
        setFormError({
          ...formError,
          [name]: "This is a required field."
        });
      } else if (!emailRegex.test(value)) {
        setFormError({
          ...formError,
          [name]: "Please enter valid email"
        });
      } else {
        setFormError({
          ...formError,
          [name]: ""
        });
      }
    } else if (name === "phone_number") {
      if (value !== "") {
        if (value?.length < 10) {
          setFormError({
            ...formError,
            [name]: "Please enter valid phonenumber"
          });
        } else {
          setFormError({
            ...formError,
            [name]: ""
          });
        }
      } else {
        setFormError({
          ...formError,
          [name]: ""
        });
      }
    }
  };

  // Allow only Numeric Values in phonenumber input
  const handleNumaricValues = (e) => {
    const { key } = e;
    if (
      key === "Backspace" ||
      key === "ArrowLeft" ||
      key === "ArrowRight" ||
      key === "Tab"
    ) {
      return;
    }
    if (!/^\d$/.test(key)) {
      e.preventDefault();
    }
  };

  // Allow only Alphabets in Firstname and lastname inputs
  const handleAlphabetsValues = (e) => {
    const { key } = e;
    if (
      key === "Backspace" ||
      key === "ArrowLeft" ||
      key === "ArrowRight" ||
      key === "Tab"
    ) {
      return;
    }
    if (!/^[a-zA-Z]$/.test(key)) {
      e.preventDefault();
    }
  };

  // submit function
  const handleSubmit = (e) => {
    const requiredFormData = {
      email: formData.email
    };
    e.preventDefault();
    const newErrorMessages = {};
    Object.keys(requiredFormData).forEach((key) => {
      if (!requiredFormData[key]) {
        newErrorMessages[key] = "This is a required field.";
      } else if (
        key === "email" &&
        !emailRegex.test(requiredFormData["email"])
      ) {
        newErrorMessages[key] = "Please enter valid email";
      }
      if (formData.phone_number !== "") {
        if (formData.phone_number.length < 10) {
          newErrorMessages["phone_number"] = "Please enter valid phonenumber";
        }
      }

      if (Object.keys(newErrorMessages).length > 0) {
        setFormError(newErrorMessages);
      } else {
        const reqBody = {
          RSAClientId: clientId,
          ClientStoreId: storeId,
          email: formData?.email,
          first_name: formData?.first_name,
          last_name: formData?.last_name,
          phone_number: formData?.phone_number,
          zipcode: formData?.zipcode,
          POSStoreId: formData?.store
        };
        dispatch(constantcontactDetail(reqBody));
      }
    });
  };

  useEffect(() => {
    if (constantContactData?.status === "success") {
      setSuccessMessage(constantContactData?.message);
      setFormData({
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        zipcode: "",
        store: ""
      });
    } else {
      setErrorMessage(constantContactData?.message);
    }
  }, [constantContactData, constantContactError]);

  useEffect(() => {
    setSuccessMessage("");
    setErrorMessage("");
  }, [location]);

  const onChangeReCaptcha = (value) => {
    setRecaptchaValue(value);
    setCaptchaError("");
  };
  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title="Subscriber to White's Perks"
      />
      {commonDetailsData?.header_section?.allow_constant_contact_form ===
      "1" ? (
        <div className="contact-section">
          <div className="container">
            <div className="title-wrap">
              <h1>{commonDetailsData?.header_section?.client_name} Perks!</h1>
            </div>
            <div className="contact-content">
              <div className="contact-desc">
                <p>
                  We really appreciate that you’ve chosen us to help you provide
                  healthy, affordable, and fun food options for you and your
                  family. Join one or more of our mailing lists and we’ll send
                  you weekly specials from the store of your choice, special
                  pricing on “while they last” items, and information about
                  events.
                </p>
              </div>
              <div className="form-wrapper">
                <div className="form-lable">
                  Email (required) <span title="required">*</span>
                </div>
                <input
                  onChange={formHandling}
                  name="email"
                  required={true}
                  type="text"
                  value={formData?.email}
                  placeholder="Email"
                  className="input-label"></input>
              </div>
              {formError?.email && (
                <div className="form-wrapper">
                  <div className="form-lable"></div>
                  <span className="error-message" style={{ color: "red" }}>
                    {formError?.email}
                  </span>
                </div>
              )}
              <div className="form-wrapper">
                <div className="form-lable">First Name</div>
                <input
                  type="text"
                  onKeyDown={handleAlphabetsValues}
                  onChange={formHandling}
                  name="first_name"
                  value={formData?.first_name}
                  placeholder="First Name"
                  className="input-label"></input>
              </div>
              <div className="form-wrapper">
                <div className="form-lable">Last Name</div>
                <input
                  onChange={formHandling}
                  onKeyDown={handleAlphabetsValues}
                  name="last_name"
                  type="text"
                  value={formData?.last_name}
                  placeholder="Last Name"
                  className="input-label"></input>
              </div>
              <div className="form-wrapper">
                <div className="form-lable">Phone Number</div>
                <input
                  onChange={formHandling}
                  onKeyDown={handleNumaricValues}
                  name="phone_number"
                  type="text"
                  value={formData?.phone_number}
                  maxlength="10"
                  placeholder='If you would like to receive "while they last" specials by text, please give us your cell number.'
                  className="input-label"></input>
                {formError?.phone_number && (
                  <span className="error" style={{ color: "red" }}>
                    {formError?.phone_number}
                  </span>
                )}
              </div>
              <div className="form-wrapper">
                <div className="form-lable">Zip</div>
                <input
                  name="zipcode"
                  type="text"
                  placeholder="Zip"
                  onKeyDown={handleNumaricValues}
                  maxlength="5"
                  className="input-label"
                  onChange={formHandling}
                />
              </div>
              <div className="form-wrapper">
                <div className="form-lable">Store</div>
                <select
                  name="store"
                  onChange={formHandling}
                  value={formData?.store}>
                  {storeListData?.map((each) => (
                    <option value={each?.POSStoreId}>
                      {each?.ClientStoreName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-wrapper">
                <div className="form-lable"></div>
                <div className="re-captcha-btn">
                  <div className="re-captcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={your_recaptcha_site_key}
                      onChange={onChangeReCaptcha}
                    />
                    <span style={{ color: "red" }}>{captchaError}</span>
                  </div>
                  <div className="signup-btn-wrapper">
                    <button
                      className="signup-btn"
                      type="submit"
                      onClick={handleSubmit}
                      style={{
                        cursor: constantContactLoading
                          ? "not-allowed"
                          : "pointer"
                      }}
                      disabled={constantContactLoading}>
                      {constantContactLoading ? (
                        <span>Submitting...</span>
                      ) : (
                        <span>Submit</span>
                      )}
                    </button>

                    {successMessage && (
                      <div className="support-div">
                        <p style={{ color: "green" }}> {successMessage}</p>
                      </div>
                    )}
                    {errorMessage && (
                      <div className="support-div">
                        <p style={{ color: "red" }}> {errorMessage}</p>
                      </div>
                    )}
                  </div>
                  <div className="message">
                    By submitting this form, you are consenting to receive
                    marketing emails from: . You can revoke your consent to
                    receive emails at any time by using the SafeUnsubscribe®
                    link, found at the bottom of every email.{" "}
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      Emails are serviced by Constant Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="support-div">
          <h1>Contact to support</h1>
        </div>
      )}
    </>
  );
};

export default ConstantContact;
