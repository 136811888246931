import {
    ANALYTICS_PRODUCT_TRACK,
    ANALYTICS_PRODUCT_TRACK_SUCCESS,
    ANALYTICS_PRODUCT_TRACK_FAILURE,
    ANALYTICS_PRODUCT_SEARCH,
    ANALYTICS_PRODUCT_SEARCH_SUCCESS,
    ANALYTICS_PRODUCT_SEARCH_FAILURE,
    MEDIA_ANALYTICS_CLICK,
    MEDIA_ANALYTICS_CLICK_SUCCESS,
    MEDIA_ANALYTICS_CLICK_FAILURE,
    MEDIA_ANALYTICS_IMPRESSION,
    MEDIA_ANALYTICS_IMPRESSION_SUCCESS,
    MEDIA_ANALYTICS_IMPRESSION_FAILURE,
    ORDER_ANALYTICS,
    ORDER_ANALYTICS_SUCCESS,
    ORDER_ANALYTICS_FAILURE,
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
  // send Product Track to analytics
  export const postAnalyticsProductTrack = (payload) => {
    return {
      type: ANALYTICS_PRODUCT_TRACK,
      payload: payload,
    };
  };
  export const postAnalyticsProductTrackSuccess = (payload) => {
    return {
      type: ANALYTICS_PRODUCT_TRACK_SUCCESS,
      payload: payload,
    };
  };
  export const postAnalyticsProductTrackFailure = (payload) => {
    return {
      type: ANALYTICS_PRODUCT_TRACK_FAILURE,
      payload: payload,
    };
  };
  
  export const analyticsProductTrack = (body) => async (dispatch) => {
    dispatch(postAnalyticsProductTrack());
    axios
      .post(`https://atrdhdt1zc.execute-api.us-east-1.amazonaws.com/prod/analytics-events/analytics/productTracking`,body);
  };

  // send Product Search to analytics
  export const postAnalyticsProductSearch = (payload) => {
    return {
      type: ANALYTICS_PRODUCT_SEARCH,
      payload: payload,
    };
  };
  export const postAnlyticsProductSearchSuccess = (payload) => {
    return {
      type: ANALYTICS_PRODUCT_SEARCH_SUCCESS,
      payload: payload,
    };
  };
  export const postAnlyticsProductSearchFailure = (payload) => {
    return {
      type: ANALYTICS_PRODUCT_SEARCH_FAILURE,
      payload: payload,
    };
  };

 
  
  export const analyticsProductSearch = (body) => async (dispatch) => {
    dispatch(postAnalyticsProductSearch());
    axios
      .post(`https://atrdhdt1zc.execute-api.us-east-1.amazonaws.com/prod/analytics-events/analytics/n-search/search/analytics-events/analytics/n-search/search`, body);
  };

  // send Media Click to analytics
  export const postMediaAnalyticsClick = (payload) => {
    return {
      type: MEDIA_ANALYTICS_CLICK,
      payload: payload,
    };
  };
  export const postMediaAnalyticsClickSuccess = (payload) => {
    return {
      type: MEDIA_ANALYTICS_CLICK_SUCCESS,
      payload: payload,
    };
  };
  export const postMediaAnalyticsClickFailure = (payload) => {
    return {
      type: MEDIA_ANALYTICS_CLICK_FAILURE,
      payload: payload,
    };
  };

  export const mediaAnalyticsClick = (mediaId, clientId, member_number) => async (dispatch) => {
    dispatch(postMediaAnalyticsClick());
    const body = {
      "RSAClientId": clientId ?? "",
      "member_number": member_number ?? ""
    };
    axios
      .post(`/media-analytics/click/${mediaId}`, body);
  };
  // send Media Impression to analytics
  export const postMediaAnalyticsImpression = (payload) => {
    return {
      type: MEDIA_ANALYTICS_IMPRESSION,
      payload: payload,
    };
  };
  export const postMediaAnalyticsImpressionSuccess = (payload) => {
    return {
      type: MEDIA_ANALYTICS_IMPRESSION_SUCCESS,
      payload: payload,
    };
  };
  export const postMediaAnalyticsImpressionFailure = (payload) => {
    return {
      type: MEDIA_ANALYTICS_IMPRESSION_FAILURE,
      payload: payload,
    };
  };

  export const mediaAnalyticsImpression = (mediaId, clientId, member_number) => async (dispatch) => {
    dispatch(postMediaAnalyticsImpression());
    const body = {
      "RSAClientId": clientId ?? "",
      "member_number": member_number ?? ""
    };
    axios
      .post(`/media-analytics/impression/${mediaId}`,body);
  };

  // send Order Analytics to analytics
  export const postOrderAnalytics = (payload) => {
    return {
      type: ORDER_ANALYTICS,
      payload: payload,
    };
  };
  export const postOrderAnalyticsSuccess = (payload) => {
    return {
      type: ORDER_ANALYTICS_SUCCESS,
      payload: payload,
    };
  };
  export const postOrderAnalyticsFailure = (payload) => {
    return {
      type: ORDER_ANALYTICS_FAILURE,
      payload: payload,
    };
  };

  export const saveOrderAnalytics = (body) => async (dispatch) => {
    dispatch(postOrderAnalytics());
    axios
      .post(`/order-analytics`, body);
  };

