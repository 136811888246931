import React from "react";
import Home from "../../assets/icons/home.webp";
import WeeklyAd from "../../assets/icons/weekly_ad.jpg";
import Account from "../../assets/icons/account.png";
import Coupon from "../../assets/icons/coupon.webp";
import "./mobile-bottom-sticky.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MobileBottomSticky = () => {
  const { menu } = useSelector(({ common }) => common.commonDetailsData);

  const weeklyAdRedirectLink = menu?.find((each) =>
    each.menu_url.includes("weekly-ads")
  );

  return (
    <div className="mobile-sticky-bar">
      <div className="mobile-sticky-bar-item home active">
        <Link to="/home" data-tooltip="home" >
          <img src={Home} alt="home" height={22} width={22} />
        </Link>
      </div>
      <div className="mobile-sticky-bar-item weekly-ad">
        <Link
          to={
            weeklyAdRedirectLink?.menu_url
              ? weeklyAdRedirectLink?.menu_url
              : "/"
          } data-tooltip="weekly ad" >
          <img src={WeeklyAd} alt="weekly-ad" height={22} width={22} />
        </Link>
      </div>
      <div className="mobile-sticky-bar-item list">
        <Link to="/login" data-tooltip="sign in" >
        <img src={Account} alt="account" height={22} width={22} />
        </Link>
      </div>
      <div className="mobile-sticky-bar-item coupons">
        <Link to="/coupons" data-tooltip="coupons" >
        <img src={Coupon} alt="coupon" height={22} width={25} />
        </Link>
      </div>
    </div>
  );
};

export default MobileBottomSticky;
