import {
  FETCH_PRODUCT_LIST_BY_DEPARTMENT,
  FETCH_PRODUCT_LIST_BY_DEPARTMENT_SUCCESS,
  FETCH_PRODUCT_LIST_BY_DEPARTMENT_FAILURE,
  FETCH_FILTER_OPTIONS_LIST,
  FETCH_FILTER_OPTIONS_LIST_SUCCESS,
  FETCH_FILTER_OPTIONS_LIST_FAILURE,
  UPDATE_SORT_BY_VALUE,
  UPDATE_PAGE_LIMIT,
  UPDATE_IFT_CHECK_LIST,
  EMPTY_IFT_CHECK_LIST,
  UPDATE_PAST_ORDER_LIST,
  UPDATE_ACTIVE_PAGE,
  FETCH_DEPARTMENT_TREE,
  FETCH_DEPARTMENT_TREE_SUCCESS,
  FETCH_DEPARTMENT_TREE_FAILURE,
  UPDATE_PRICE_VALUE,
  UPDATE_NODE_FUNCTIONAL_TEXT,
  UPDATE_NODE_FUNCTIONAL_LEVEL,
  SEARCH_DEPARTMENT_TREE,
  SEARCH_DEPARTMENT_TREE_SUCCESS,
  SEARCH_DEPARTMENT_TREE_FAILURE,
  RESET_FILTER_SIDEBAR,
  FETCH_PRODUCT_DETAILS,
  FETCH_PRODUCT_DETAILS_SUCCESS,
  FETCH_PRODUCT_DETAILS_FAILURE,
  FETCH_RELATED_PRODUCT_LIST,
  FETCH_RELATED_PRODUCT_LIST_SUCCESS,
  FETCH_RELATED_PRODUCT_LIST_FAILURE,
  UPDATE_FROM_LIST,
  SAVE_CURRENT_DEPARTMENT,
  WEEKLY_SALES_SELECTED,
  UPDATE_DEPARTMENT_NAME,
  GET_PRODUCTS_UPC_WISE_FAILURE,
  GET_PRODUCTS_UPC_WISE_SUCCESS,
  GET_PRODUCTS_UPC_WISE_REQUEST,
} from "redux/constants/actionTypes";

let initialState = {
  productListByDepartment: [],
  productListByDepartmentLoading: false,
  productListByDepartmentError: "",
  productListByDepartmentTotalCount: 0,
  productListByDepartmentTotalPages: 0,
  categoryadsSection: {},
  filterOptionList: {},
  filterOptionListLoading: false,
  filterOptionListError: "",
  activePageNo: 1,
  pageLimit: "",
  iftCheckedList: [],
  fromCheckedList: [],
  pastOrderCheckedList: [],
  sortByValue: "",
  maxPriceValue: "",
  minPriceValue: "",
  functionalText: "",
  functionalLevel: "",
  departmentTree: [],
  departmentTreeLoading: false,
  departmentTreeError: "",
  searchDepartmentTree: [],
  searchDepartmentTreeLoading: false,
  searchDepartmentTreeError: "",
  nodeFunctionalText: "",
  nodeFunctionalLevel: "",
  productDetails: null,
  productDetailsLoading: false,
  productDetailsError: "",
  relatedProductList: [],
  relatedProductListLoading: false,
  relatedProductListError: "",
  currentDepartment: "",
  filterOptions: [],
  weeklySalesSelected: false,
  selectedDepartmentName: "",
  productsUpcWise: [],
  productsUpcWiseLoading: false,
  productsUpcWiseError: "",
  memoryToken: ""
};

const productListByDepartmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_LIST_BY_DEPARTMENT:
      return {
        ...state,
        productListByDepartmentLoading: true,
        productListByDepartment: [],
      };
    case FETCH_PRODUCT_LIST_BY_DEPARTMENT_SUCCESS:
      return {
        ...state,
        productListByDepartmentLoading: false,
        productListByDepartment: action.payload.data,
        filterOptions: action.payload.filters,
        productListByDepartmentTotalCount: action.payload.TotalRecords || 0,
        productListByDepartmentTotalPages: action.payload.NoOfPages || 0,
        categoryadsSection: action.payload.categories_section,
        memoryToken: action.payload.memoryToken
      };
    case FETCH_PRODUCT_LIST_BY_DEPARTMENT_FAILURE:
      return {
        ...state,
        productListByDepartmentError: action.payload.error,
        productListByDepartmentLoading: false,
      };

    // Filter option list
    case FETCH_FILTER_OPTIONS_LIST:
      return {
        ...state,
        filterOptionListLoading: true,
      };
    case WEEKLY_SALES_SELECTED:
      return {
        ...state,
        weeklySalesSelected: action.payload,
      };
    case UPDATE_DEPARTMENT_NAME:
      return {
        ...state,
        selectedDepartmentName: action.payload,
      };
    case FETCH_FILTER_OPTIONS_LIST_SUCCESS:
      return {
        ...state,
        filterOptionListLoading: false,
        filterOptionList: action.payload,
      };
    case FETCH_FILTER_OPTIONS_LIST_FAILURE:
      return {
        ...state,
        filterOptionListError: action.payload.error,
      };
    case RESET_FILTER_SIDEBAR:
      return {
        ...state,
        activePageNo: 1,
        pageLimit: "",
        iftCheckedList: [],
        sortByValue: "",
        maxPriceValue: "",
        minPriceValue: "",
        functionalText: "",
        functionalLevel: "",
      };
    // Filter select
    case UPDATE_SORT_BY_VALUE:
      return {
        ...state,
        sortByValue: action.payload,
        activePageNo: 1,
      };
    case UPDATE_PAGE_LIMIT:
      return {
        ...state,
        pageLimit: action.payload,
        activePageNo: 1,
      };
    case UPDATE_IFT_CHECK_LIST:
      let updatedIftCheckedList = state.iftCheckedList;

      if (updatedIftCheckedList.includes(action.payload)) {
        updatedIftCheckedList = updatedIftCheckedList.filter(
          (iftType) => iftType !== action.payload
        );
      } else {
        updatedIftCheckedList.push(action.payload);
      }

      return {
        ...state,
        iftCheckedList: updatedIftCheckedList,
        activePageNo: 1,
      };

    case EMPTY_IFT_CHECK_LIST:
      return { ...state, iftCheckedList: [], activePageNo: 1 };

    case UPDATE_FROM_LIST:
      let updatedFromList = state.fromCheckedList;

      if (updatedFromList.includes(action.payload)) {
        updatedFromList = updatedFromList.filter(
          (fromList) => fromList !== action.payload
        );
      } else {
        updatedFromList.push(action.payload);
      }

      return {
        ...state,
        fromCheckedList: updatedFromList,
        activePageNo: 1,
      };
    case UPDATE_PAST_ORDER_LIST:
      let pastOrderList = state.pastOrderCheckedList;

      if (pastOrderList.includes(action.payload)) {
        pastOrderList = pastOrderList.filter(
          (pastDate) => pastDate !== action.payload
        );
      } else {
        pastOrderList.push(action.payload);
      }

      return {
        ...state,
        pastOrderCheckedList: pastOrderList,
        activePageNo: 1,
      };

    case UPDATE_ACTIVE_PAGE:
      return {
        ...state,
        activePageNo: action.payload,
      };

    case UPDATE_PRICE_VALUE:
      return {
        ...state,
        minPriceValue: action.payload[0] + "",
        maxPriceValue: action.payload[1] + "",
      };

    // Department tree view
    case FETCH_DEPARTMENT_TREE:
      return {
        ...state,
        departmentTreeLoading: true,
      };
    case FETCH_DEPARTMENT_TREE_SUCCESS:
      return {
        ...state,
        departmentTreeLoading: false,
        departmentTree: action.payload,
      };
    case FETCH_DEPARTMENT_TREE_FAILURE:
      return {
        ...state,
        departmentTreeError: action.payload.error,
      };

    // Product details
    case FETCH_PRODUCT_DETAILS:
      return {
        ...state,
        productDetailsLoading: true,
        productDetails: null,
      };
    case FETCH_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetailsLoading: false,
        productDetails: action.payload,
      };
    case FETCH_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        productDetailsError: action.payload.error,
      };

    // Related prioduct list
    case FETCH_RELATED_PRODUCT_LIST:
      return {
        ...state,
        relatedProductListLoading: true,
      };
    case FETCH_RELATED_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        relatedProductListLoading: false,
        relatedProductList: action.payload,
      };
    case FETCH_RELATED_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        relatedProductListError: action.payload.error,
      };

    // Search Department tree view
    case SEARCH_DEPARTMENT_TREE:
      return {
        ...state,
        searchDepartmentTreeLoading: true,
      };
    case SEARCH_DEPARTMENT_TREE_SUCCESS:
      return {
        ...state,
        searchDepartmentTreeLoading: false,
        searchDepartmentTree: action.payload,
      };
    case SEARCH_DEPARTMENT_TREE_FAILURE:
      return {
        ...state,
        searchDepartmentTreeError: action.payload.error,
      };
    case UPDATE_NODE_FUNCTIONAL_TEXT:
      return {
        ...state,
        nodeFunctionalText: action.payload,
      };
    case UPDATE_NODE_FUNCTIONAL_LEVEL:
      return {
        ...state,
        nodeFunctionalLevel: action.payload,
      };
    case SAVE_CURRENT_DEPARTMENT:
      return {
        ...state,
        currentDepartment: action.payload,
      };
    case GET_PRODUCTS_UPC_WISE_REQUEST:
      return {
        ...state,
        productsUpcWiseLoading: true,
      };
    case GET_PRODUCTS_UPC_WISE_SUCCESS:
      return {
        ...state,
        productsUpcWiseLoading: false,
        productsUpcWise: action.payload,
      };
    case GET_PRODUCTS_UPC_WISE_FAILURE:
      return {
        ...state,
        productsUpcWiseError: action.payload.error,
      };
    default:
      return state;
  }
};

export default productListByDepartmentReducer;
