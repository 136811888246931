import React, { useEffect, useState } from "react";
import ProductImage from "../../assets/images/preview-product.png";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import "./reward-details.scss";
import RewardDetailsItem from "./RewardDetailsItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getGetQualifiedRewardTiersV2Details,
  getPointsBasedRewardDetails,
  setSelectedRedeemOption,
} from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";

const RewardDetails = () => {
  const breadcrumbList = [{ lable: "Rewards Detail", link: "", searchTag: "" }];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const { PointsBasedRewardData } = useSelector(({ reward }) => reward);
  const {
    QualifiedRewardTiersV2Data,
    setRedeemSuccessMessage,
    setRedeemLoading,
  } = useSelector(({ reward }) => reward);

  const location = useLocation();
  const { lmRewardId, Redeem, isPointBased, lmRewardData } = location.state;

  const RewardDetailsData =
    //  Redeem?
    QualifiedRewardTiersV2Data?.RewardDetails;
  // : PointsBasedRewardData?.RewardDetails;
  const RewardTiersData =
    // Redeem?
    QualifiedRewardTiersV2Data?.QualifiedRewardTiers;
  // : PointsBasedRewardData?.RewardTiers;

  const LM_Reward_ID = RewardDetailsData?.LMRewardID;
  const memberNumber = getLocalStorageValue("member-number");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");
  const [selectedRedeem, setSelectedRedeem] = useState({
    couponValue: null,
    pointsRequired: null,
    tierId: null,
    couponTypeId: null,
  });

  const ImageURL = isPointBased
    ? lmRewardData?.RewardImageURL
    : lmRewardData?.image_url;
  const MemberPointsString = isPointBased
    ? RewardDetailsData?.MemberPointsString
    : lmRewardData?.PurchasedAmountString;
  const RewardPointsText = isPointBased
    ? RewardDetailsData?.RewardPointsText
    : lmRewardData?.RewardPurchasedAmountText;
  const Title = isPointBased ? RewardDetailsData?.Title : lmRewardData?.title;

  useEffect(() => {
    let body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      member_number: isUserLoggedIn ? memberNumber : "42115480278",
      lm_reward_id: lmRewardId,
      user_token: isUserLoggedIn ? userToken : "",
      AppName: isUserLoggedIn ? "shop" : "",
    };

    if (isPointBased) {
      // if (Redeem) {
      dispatch(getGetQualifiedRewardTiersV2Details(body));
      // } else {
      dispatch(getPointsBasedRewardDetails(body));
      // }
    }
  }, [
    Redeem,
    clientId,
    dispatch,
    isUserLoggedIn,
    lmRewardId,
    memberNumber,
    storeId,
    userToken,
    isPointBased,
  ]);

  const handleRedeemClick = async () => {
    const { value, point, tierId, rewardId } = selectedRedeem;

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      tier_id: tierId,
      lm_reward_id: RewardDetailsData?.LMRewardID,
      points_required: point,
      coupon_value: value,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    try {
      await dispatch(setSelectedRedeemOption(body));
      window.scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        window.location.href = "/rewards";
      }, 2000);
    } catch (error) {
      console.log("error", error);
    }
    // window.location.href = "/rewards";
  };

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={"Rewards Point"} //Need to pass title through api res
      />
      <div className="detail-reward-main-wrap">
        <div className="container">
          <div className="detail-reward-box">
            <div className="detail-reward-img">
              <img src={ImageURL} alt="product_image" />
            </div>
            <div className="detail-reward-content-wrapper">
              {isPointBased && (
                <div className="detail-reward-range-bar">
                  <div className="detail-reward-point">
                    <span>{MemberPointsString}</span>
                  </div>
                </div>
              )}
              {isPointBased && (
                <div className="detail-reward-subtitle">{RewardPointsText}</div>
              )}
              <div className="detail-reward-title-btn">
                <div className="detail-reward-title">{Title}</div>
              </div>
              {isPointBased && (
                <p style={{ color: "red" }}>
                  *Check one reward option at a time and click Redeem.
                </p>
              )}
              {setRedeemSuccessMessage && (
                <div className="success-msg show">
                  {setRedeemSuccessMessage.status === "success" && (
                    <p>{setRedeemSuccessMessage.message}</p>
                  )}
                  {setRedeemSuccessMessage.status === "error" && (
                    <p className="error-msg">Reward is not issued.</p>
                  )}
                </div>
              )}
            </div>
            {!Redeem &&
              isUserLoggedIn &&
              lmRewardData?.is_points_based &&
              lmRewardData?.IsItQualifiedforReward && (
                <div className="reward-btn redeem-btn">
                  <Link
                    to="/rewardsdetails"
                    state={{
                      lmRewardId: lmRewardData?.lm_reward_id,
                      Redeem: true,
                      isPointBased: lmRewardData?.is_points_based,
                      lmRewardData: lmRewardData,
                    }}
                  >
                    Redeem
                  </Link>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="reward-details-wrap">
        <div className="container">
          <div className="reward-details-flex-item">
            {isPointBased ? (
              RewardTiersData?.map((eachRewardTier, i) => {
                return (
                  <RewardDetailsItem
                    eachRewardTier={eachRewardTier}
                    LM_Reward_ID={LM_Reward_ID}
                    Redeem={Redeem}
                    index={i}
                    selectedRedeem={selectedRedeem}
                    setSelectedRedeem={setSelectedRedeem}
                  />
                );
              })
            ) : (
              <div>
                <div className="details-spend-reward-title">
                  <span>Additional Details</span>
                </div>
                <div className="spend-reward-details">
                  <span>{lmRewardData?.reward_details}</span>
                </div>
                <div className="spend-reward-numbers">
                  {lmRewardData?.valid_upcs}{" "}
                </div>
              </div>
            )}
          </div>
          {Redeem && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  backgroundColor: Object.values(selectedRedeem).every(
                    (value) => value === null
                  )
                    ? "#e1d8d8"
                    : "red",
                  color: "white",
                  width: "12rem",
                  padding: "1rem",
                  border: "none",
                  borderRadius: "2rem",
                }}
                disabled={Object.values(selectedRedeem).every(
                  (value) => value === null
                )}
                onClick={() => {
                  handleRedeemClick();
                }}
              >
                {setRedeemLoading ? "Loading... Please Wait" : "Redeem"}
              </button>
              <p style={{ color: "red" }}>
                * Only one reward can be applied at a time. Select one and click
                Redeem.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RewardDetails;
