import {
    FETCH_BANNER_ADS_DATA,
    FETCH_BANNER_ADS_DATA_SUCCESS,
    FETCH_BANNER_ADS_DATA_FAILURE,
    
  } from "redux/constants/actionTypes";
  
  let initialState = {
    bannerAdsData: {},
    bannerAdsDataLoading: true,
    bannerAdsDataError: "",
  };
  
  const bannerAdsReducer = (state = initialState, action) => {
  
    switch (action.type) {
      case FETCH_BANNER_ADS_DATA:
        return {
          ...state,
          bannerAdsDataLoading: true,
        };
      case FETCH_BANNER_ADS_DATA_SUCCESS:
        return {
          ...state,
          bannerAdsDataLoading: false,
          bannerAdsData: action.payload,
        };
      case FETCH_BANNER_ADS_DATA_FAILURE:
        return {
          ...state,
          bannerAdsDataLoading: false,
          bannerAdsDataError: action.payload.error,
        };
  
      default:
        return state;
    }
  };
  
  export default bannerAdsReducer;
  