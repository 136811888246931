import React, { useEffect, useState } from "react";
import "./edit-profile.scss";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  deleteUserAccount,
  getCustomerDetails,
  updateCustomerProfile,
  clearUpdateProfileMessage
} from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import ChangeEmailModal from "./ChangeEmailModal";

const EditProfileTheme2 = ({ onUpdateProfile }) => {
  //  const [storeMenu, setStoreMenu] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const dispatch = useDispatch();
  const storeList = useSelector(({ store }) => store.storeListData);
  const { currentStoreID } = useSelector(({ store }) => store);
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientStorename = getLocalStorageValue("client-storename");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const customerData = useSelector(({ customer }) => customer.customerlistData);
  const [changeEmail, setChangeEmail] = useState(false);

  const [isOpenModalChangeEmail, setIsOpenModalChangeEmail] = useState(false);
  const [changeEmailConfirm, setChangeEmailConfirm] = useState(false);

  const {
    delete_account_title,
    delete_account_paragraph_1,
    delete_account_paragraph_2,
    allow_change_email_id_in_my_account
  } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const defaultValues = {
    phoneNo: customerData?.mobile_number ?? "",
    firstName: customerData?.first_name ?? "",
    lastName: customerData?.last_name ?? "",
    // icontact_selected_value binded [data coming from backend ] by Kiran Konda verified by [Keyur on 09/09/2024]
    icontact_selected_value: customerData?.icontact_selected_value
  };
  const { register, handleSubmit, errors, watch } = useForm({ defaultValues });

  const store = storeList.find(
    (store) => store.ClientStoreName === clientStorename
  );

  const {
    updateProfileStatus,
    updateProfileData,
    updateProfileMessage,
    updateProfileLoading
  } = useSelector(({ customer }) => customer);

  const { deleteUserAccountLoading } = useSelector(({ user }) => user);

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const [selectedStore, setSelectedStore] = useState(storeId);

  const handleSelectStore = (event) => {
    setSelectedStore(event.target.value);
  };

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    dispatch(getCustomerDetails(body));
  }, [updateProfileStatus]);

  const handleUpdateProfile = (data, e) => {
    const { firstName, lastName, phoneNo, icontact_selected_value } = data;
    const body = {
      RSAClientId: clientId,
      ClientStoreId: selectedStore ? selectedStore : store.ClientStoreId,
      first_name: firstName,
      last_name: lastName,
      mobile_number: phoneNo,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      icontact_selected_value: icontact_selected_value
    };
    dispatch(updateCustomerProfile(body));
  };

  const handleDeleteProfile = (data, e) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: selectedStore ? selectedStore : store.ClientStoreId,
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : ""
    };

    dispatch(deleteUserAccount(body));
  };

  useEffect(() => {
    return () => {
      dispatch(clearUpdateProfileMessage());
    };
  }, [dispatch]);

  const handleClose = () => {
    setIsOpenModalChangeEmail(false);
  };
  const headerSection = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );
  const icontactOptions = useSelector(
    ({ common }) => common.commonDetailsData?.icontact_stay_connected_option
  );

  return (
    <div>
      <div className="edit-profile-section-theme2">
        <h1>Edit Profile</h1>
        {customerData && updateProfileData && (
          <>
            <div className="edit-profile-box">
              <form
                name="updateprofile"
                onSubmit={handleSubmit(handleUpdateProfile)}>
                <div className="text-grid">
                  <div className="text-grid-items">
                    <p>Email: </p>
                  </div>
                  <div className="text-grid-items">
                    <span>{customerData.email_id}</span>
                    {allow_change_email_id_in_my_account == 1 && (
                      <span>
                        <button
                          type="button"
                          className="btn btn-danger edit-email"
                          style={{
                            cursor: "pointer"
                          }}
                          onClick={() => setChangeEmailConfirm(true)}>
                          Change Email
                        </button>
                      </span>
                    )}
                  </div>
                </div>
                {changeEmailConfirm && (
                  <div className="modalContainer">
                    <div className="modal confirmation-modal">
                      <header className="modal_header">
                        <h2 className="modal_header-title">
                          Are you sure to change your Email?
                        </h2>
                      </header>

                      <div className="expireContent email-permission">
                        <footer className="modal_footer">
                          <div className="modal_footer_btn">
                            <div className="addBtn">
                              <button
                                className="btn"
                                onClick={() => setIsOpenModalChangeEmail(true)}>
                                Yes
                              </button>
                            </div>
                            <button
                              className="modal-close"
                              onClick={() => {
                                setChangeEmailConfirm(false);
                                setIsOpenModalChangeEmail(false);
                              }}>
                              No
                            </button>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                )}
                {isOpenModalChangeEmail && (
                  <ChangeEmailModal
                    changeEmail={isOpenModalChangeEmail}
                    handleClose={handleClose}
                    setChangeEmailConfirm={setChangeEmailConfirm}
                  />
                )}
                <div className="two-col-grid">
                  <div className="two-col-grid-items">
                    <div className="form-control">
                      <label>First Name*</label>
                      <input
                        type="text"
                        name="firstName"
                        ref={register({
                          required: true,
                          pattern: /^[A-Z0-9a-z_-\s]+$/
                        })}
                      />
                      {errors.firstName &&
                        errors.firstName.type === "required" && (
                          <span>Please enter your First Name</span>
                        )}
                      {errors.firstName &&
                        errors.firstName.type === "pattern" && (
                          <span>Please enter valid First Name</span>
                        )}
                    </div>
                  </div>

                  <div className="two-col-grid-items">
                    <div className="form-control">
                      <label>Last Name*</label>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        ref={register({
                          required: true,
                          pattern: /^[A-Z0-9a-z_-\s]+$/
                        })}
                      />
                      {errors.lastName &&
                        errors.lastName.type === "required" && (
                          <span>Please enter your Last Name</span>
                        )}
                      {errors.lastName &&
                        errors.lastName.type === "pattern" && (
                          <span>Please enter valid Last Name</span>
                        )}
                    </div>
                  </div>
                  <div className="two-col-grid-items">
                    <div className="form-control">
                      <label>Phone</label>
                      <input
                        type="text"
                        name="phoneNo"
                        id="phoneNo"
                        maxLength={10}
                        ref={register({
                          required: false,
                          pattern: /^[0-9]{10}$/
                        })}
                      />
                      {errors.phoneNo && errors.phoneNo.type === "required" && (
                        <span>Please enter phone.</span>
                      )}
                      {errors.phoneNo && errors.phoneNo.type === "pattern" && (
                        <span>Please enter valid phone no.</span>
                      )}
                    </div>
                  </div>
                  <div className="two-col-grid-items">
                    <div className="form-control">
                      <label>
                        Preferred Store<span>*</span>
                      </label>
                      <select
                        value={selectedStore}
                        name="selectStore"
                        onChange={handleSelectStore}
                        className="form-control show-items dropdown-align select-dropdown"
                        ref={register({
                          required: true
                        })}>
                        {storeList.length > 0 &&
                          storeList.map((store) => {
                            return (
                              <option value={store.ClientStoreId}>
                                {store.ClientStoreName}
                              </option>
                            );
                          })}
                      </select>

                      {errors.selectStore &&
                        errors.selectStore.type === "required" && (
                          <span>Please Select Store.</span>
                        )}
                    </div>
                  </div>
                  <div className="two-col-grid-items">
                    <h3>Member Number</h3>
                    <p>{customerData ? customerData.member_number : ""}</p>
                  </div>
                  <div className="two-col-grid-items">
                    <h3>User ID</h3>
                    <p>{customerData ? customerData.user_id : ""}</p>
                  </div>
                  {/* <div className="two-col-grid-items"></div>
                  <div className="two-col-grid-items"></div>
                  <div className="two-col-grid-items"></div>
                  <div className="two-col-grid-items"></div> */}
                </div>

                {headerSection &&
                  headerSection?.Allow_IContact_Integration === "1" && (
                    <div className="form-control">
                      <h4 style={{ marginBottom: "8px" }}>Stay Connected </h4>
                      <p
                        className="connected-message"
                        style={{ marginBottom: "10px" }}>
                        Verify the type of email promotions you would like to
                        receive from {headerSection?.client_name} by checking
                        the boxes below
                      </p>
                      {icontactOptions &&
                        icontactOptions?.map((each) => (
                          <div className="check-wrapper">
                            <div key={each?.slug}>
                              <input
                                type="checkbox"
                                name="icontact_selected_value"
                                id={each?.slug}
                                value={each?.slug}
                                ref={register({
                                  required: watch("icontact_selected_value")
                                })}
                              />
                              <label
                                htmlFor={each?.slug}
                                className="signup-checkbox-wrap">
                                {each?.title}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                {updateProfileStatus ? (
                  <span
                    className={
                      updateProfileStatus === "success"
                        ? "feedback-success"
                        : "feedback-error"
                    }>
                    {updateProfileMessage}
                  </span>
                ) : (
                  ""
                )}

                <div className="button-right-side">
                  {/* <div>
              <button>Cancel</button>
            </div> */}
                  <div>
                    <button type="submit">
                      {updateProfileLoading ? "Saving..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <hr />
            <div className="edit-profile-box">
              <div>
                <div style={{ paddingBottom: "20px" }}>
                  <span>
                    {" "}
                    <b>{delete_account_title}</b>
                  </span>
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  <span> {delete_account_paragraph_1}</span>
                </div>
                <div style={{ paddingBottom: "20px" }}>
                  <span> {delete_account_paragraph_2}</span>
                </div>
              </div>
              <div className="button-left-side">
                <div>
                  <button
                    type="submit"
                    onClick={() => {
                      if (
                        window.confirm("Are you sure to delete the account?")
                      ) {
                        handleDeleteProfile();
                      }
                    }}>
                    {deleteUserAccountLoading ? "Saving..." : "Delete Account"}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default EditProfileTheme2;
