import React, { useCallback, useEffect, useMemo } from "react";
import "./product-banner.scss";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageValue } from "config/helper";
import { getProductsUPCWise } from "redux/actions";
import ProductCard from "components/ProductCard";

function ProductBanner({ show, gtins, setIsModalOpen }) {
  const dispatch = useDispatch();

  // Retrieve values from local storage
  const RSAClientId = useMemo(() => getLocalStorageValue("RSAclient-id"), []);
  const ClientStoreId = useSelector(({ store }) => store.currentStoreID) || getLocalStorageValue("selected-store-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const member_number = useMemo(() => getLocalStorageValue("member-number"), [isUserLoggedIn]);
  const user_token = useMemo(() => getLocalStorageValue("user-token"), []);

  // Retrieve product data from the store
  const { productsUpcWise, productsUpcWiseLoading } = useSelector(({ product }) => product);

  const fetchProducts = useCallback(() => {
    const body = {
      RSAClientId,
      ClientStoreId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? member_number : "",
      user_token: isUserLoggedIn ? user_token : "",
      PageNo: 1,
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      input_upcs: gtins
    };
    dispatch(getProductsUPCWise(body));
  }, [RSAClientId, ClientStoreId, gtins, member_number, user_token, isUserLoggedIn, dispatch]);

  useEffect(() => {
    if (show) {
      fetchProducts();
    }
  }, [show, fetchProducts]);

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setIsModalOpen();
    }
  };
  return (
    <div className="banner-section">
      {show && (
        <>
          <div className="modal-wrap">
            <div className="modal-overlay" onClick={handleOverlayClick}>
              <div className="modal-content">
                <p className="title">Banner Product</p>
                <div className="deal-all-wrapper">
                  <div className="product-list-grid">
                    {productsUpcWiseLoading ? (
                      <p>Loading...</p>
                    ) : (
                      productsUpcWise.map((product) => (
                        <ProductCard key={product.id} product={product} />
                      ))
                    )}
                  </div>
                </div>
                {!productsUpcWiseLoading && productsUpcWise && productsUpcWise.length === 0 && <p>No records found.</p>}
                <button className="close-btn" onClick={setIsModalOpen}>
                  &times;
                </button>
              </div>
            </div>
          </div>
        </>
      )
      }
    </div >
  );
}
ProductBanner.propTypes = {
  show: PropTypes.bool.isRequired,
  gtins: PropTypes.arrayOf(PropTypes.string).isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export default ProductBanner;