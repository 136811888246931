import {
  FETCH_BANNER_ADS_DATA,
  FETCH_BANNER_ADS_DATA_SUCCESS,
  FETCH_BANNER_ADS_DATA_FAILURE,
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
  
  // fetch banner Ads data
  export const fetchBannerAdsList = (payload) => {
    return {
      type: FETCH_BANNER_ADS_DATA,
      payload: payload,
    };
  };
  export const fetchBannerAdsSuccess = (payload) => {
    return {
      type: FETCH_BANNER_ADS_DATA_SUCCESS,
      payload: payload,
    };
  };
  export const fetchBannerAdsFailure = (payload) => {
    return {
      type: FETCH_BANNER_ADS_DATA_FAILURE,
      payload: payload,
    };
  };
  
  export const getBannerAdsData = (body) => async (dispatch) => {
    dispatch(fetchBannerAdsList(true));
    axios
      .post("/bannerx", body)
      .then((res) => {
        dispatch(fetchBannerAdsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchBannerAdsFailure({ error: error.data?.message }));
      });
  };
  