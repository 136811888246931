import {
  PHONESUBSCRIPTION_START,
  PHONESUBSCRIPTION_SUCCESS,
  PHONESUBSCRIPTION_FAILURE
} from "redux/constants/actionTypes";

let initialState = {
  phoneSubscriptionData: [],
  phoneSubscriptionLoading: false,
  phoneSubscriptionError: ""
};

const PhoneSubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PHONESUBSCRIPTION_START:
      return {
        ...state,
        phoneSubscriptionLoading: true
      };
    case PHONESUBSCRIPTION_SUCCESS:
      return {
        ...state,
        phoneSubscriptionLoading: false,
        phoneSubscriptionData: action.payload
      };
    case PHONESUBSCRIPTION_FAILURE:
      return {
        ...state,
        phoneSubscriptionLoading: false,
        phoneSubscriptionError: action.payload.error
      };
    default:
      return state;
  }
};

export default PhoneSubscriptionReducer;
