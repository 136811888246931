import React, { useState, useEffect } from "react";
import { useRef } from "react";
import DropdownListItem from "./DropdownListItem";
import downIcon from "assets/icons/down-black.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useDispatch } from 'react-redux';
import {
  handleUpdateCateringAndMealCustomOption
} from "redux/actions";

const CateringDropdown = ({
  optionList = [],
  groupData,
  icon,
  lable = "",
  defaultValue,
  placeholder,
  iconTag,
}) => {
  const refDropdown = useRef();
  const dispatch = useDispatch()

  const { title: label, is_add_option_price, id: groupId, values } = groupData
  const [isShowDropdownOption, setIsShowDropdownOption] = useState(false);

  useOnClickOutside(refDropdown, () => setIsShowDropdownOption(false));

  const [selectedOptionValue, setSelectedOptionValue] = useState(defaultValue);
  const [selectedOptionTitle, setSelectedOptionTitle] = useState(defaultValue);

  useEffect(() => {

    const defaultSelectedOptionData = values.filter(option => option.is_default === "1")
    const updatedDefaultOptionList = defaultSelectedOptionData.map(ele => {
      return {
        ...ele,
        is_add_option_price,
        label
      }
    })
    defaultSelectedOptionData && defaultSelectedOptionData[0] && setSelectedOptionValue(defaultSelectedOptionData[0].id)
    defaultSelectedOptionData && defaultSelectedOptionData[0] && setSelectedOptionTitle(defaultSelectedOptionData[0].value)
    defaultSelectedOptionData && defaultSelectedOptionData[0] &&
      dispatch(handleUpdateCateringAndMealCustomOption({ [groupId]: [...updatedDefaultOptionList] }))

  }, [values]) // eslint-disable-line



  const handleChange = (optionValue, title, dropdownOption) => {
    setSelectedOptionValue(optionValue);
    setSelectedOptionTitle(title)

    const { id, option_id, price, value } = dropdownOption;

    dispatch(handleUpdateCateringAndMealCustomOption({ [groupId]: [{ id, is_add_option_price, label, option_id, price, value }] }));
  };
  return (
    <div className="show-items" ref={refDropdown}>
      <button onClick={() => setIsShowDropdownOption(!isShowDropdownOption)}>
        {icon && <img src={icon} alt="Icon" />}
        {iconTag && iconTag}
        {placeholder && !selectedOptionValue ? (
          <span>{placeholder}</span>
        ) : (
          <span>{`${lable} ${selectedOptionTitle}`}</span>
        )}

        <img src={downIcon} alt="downIcon" />
      </button>

      <div
        className={
          isShowDropdownOption
            ? "dropdown-align dropdown-show"
            : "dropdown-align dropdown-hidden"
        }
      >
        <div className="list-menu">
          <ul>
            {optionList.map((dropdownOption, index) => {
              return (
                <DropdownListItem
                  optionName={dropdownOption.title}
                  optionValue={dropdownOption.value}
                  onSelectOption={handleChange}
                  key={index}
                  onClose={() => setIsShowDropdownOption(false)}
                  selectedOptionValue={selectedOptionValue}
                  dropdownOption={dropdownOption.option}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CateringDropdown;
