import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./previous-buys-section.scss";
// import ProductListSlider from "../ProductListSlider";
import { getPreviosProductDetails } from "redux/actions/homepage";
import { getLocalStorageValue } from 'config/helper';
import ProductListSliderTheme2 from "../ProductListSliderTheme2";

const PreviousBuysSection = () => {
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const previousBuysProductList = useSelector(
    ({ homepage }) => homepage.previousBuysProductList.recently_purchase || []
  );

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getPreviosProductDetails(body));
  }, [storeId]); // eslint-disable-line

  return previousBuysProductList.length ? (
    <div className="previous-buys-section-wrapper">
      <div className="container">
        <div className="previous-buys-section-alignment">
          <h1>Your previous buys</h1>
          <p>Products you've enjoyed</p>
        </div>
      </div>
      <ProductListSliderTheme2  products={previousBuysProductList} />
      {/* <ProductListSlider products={previousBuysProductList} /> */}
    </div>
  ) : (
    ""
  );
};
export default PreviousBuysSection;
