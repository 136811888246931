import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { customerAddress } from "redux/actions";
import "./single-address-section.scss";
import { getLocalStorageValue } from "config/helper";

const SingleAddressTheme2 = ({ changeEditAddress, contentDescription }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const { customerAddressLoading, customerAddressMsg } = useSelector(
    ({ checkout }) => checkout
  );

  const handleSubmitAddressForm = (data) => {
    const {
      firstName,
      lastName,
      zipCode,
      phoneNo,
      address1,
      address2,
      city,
      state,
    } = data;

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      address_1: address1,
      address_2: address2,
      contact: phoneNo,
      address_id: contentDescription.address_id,
      city: city,
      state: state,
      zip_code: zipCode,
      status: "shipping",
      is_primary: "1",
      first_name: firstName,
      last_name: lastName,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };

    dispatch(customerAddress(body));
    //.then(() => changeEditAddress())
  };

  return (
    <>
      <div className="single-address-section-theme2">
        <h1>Add an address</h1>
        <form name="register" onSubmit={handleSubmit(handleSubmitAddressForm)}>
          <div className="single-input-name-grid">
            <div className="form-control">
              <label>First Name*</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                defaultValue={contentDescription.first_name}
                ref={register({
                  required: true,
                  pattern: /^[A-Za-z0-9]+$/,
                })}
              />
              {errors.firstName && errors.firstName.type === "required" && (
                <span>Please enter your First Name</span>
              )}
              {errors.firstName && errors.firstName.type === "pattern" && (
                <span>Please enter valid First Name</span>
              )}
            </div>
            <div className="form-control">
              <label>Last Name*</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                defaultValue={contentDescription.last_name}
                ref={register({
                  required: true,
                  pattern: /^[A-Za-z0-9]+$/,
                })}
              />
              {errors.lastName && errors.lastName.type === "required" && (
                <span>Please enter your Last Name</span>
              )}
              {errors.lastName && errors.lastName.type === "pattern" && (
                <span>Please enter valid Last Name</span>
              )}
            </div>
          </div>
          <div className="form-control">
            <label>Phone*</label>
            <input
              type="text"
              name="phoneNo"
              id="phoneNo"
              defaultValue={contentDescription.contact}
              ref={register({
                required: true,
                pattern: /^[0-9]{10}$/,
              })}
            />

            {errors.phoneNo && errors.phoneNo.type === "required" && (
              <span>Please enter phone no.</span>
            )}
            {errors.phoneNo && errors.phoneNo.type === "pattern" && (
              <span>Please enter valid phone no.</span>
            )}
          </div>
          <div className="form-control">
            <label>Address*</label>
            <input
              type="text"
              name="address1"
              id="address1"
              defaultValue={contentDescription.address_1}
              ref={register({
                required: true,
              })}
            />

            <div className="single-addres-sec-line">
              <input
                type="text"
                name="address2"
                id="address2"
                defaultValue={contentDescription.address_2}
                ref={register({
                  required: false,
                })}
              />
              {errors.address1 && errors.address1.type === "required" && (
                <span>Please enter your address.</span>
              )}
            </div>
          </div>
          <div className="form-control">
            <label>City*</label>
            <input
              type="text"
              name="city"
              id="city"
              defaultValue={contentDescription.city}
              ref={register({
                required: true,
                pattern: /^[A-Za-z]+$/,
              })}
            />
            {/* {console.log("errors >> ",errors)} */}
            {errors.city && errors.city.type === "required" && (
              <span>Please enter your city.</span>
            )}
            {errors.city && errors.city.type === "pattern" && (
              <span>Please don't enter numbers.</span>
            )}
          </div>
          <div className="form-control">
            <label>Zip*</label>
            <input
              name="zipCode"
              id="zipCode"
              type="text"
              defaultValue={contentDescription.zip_code}
              ref={register({
                required: true,
                pattern: /^[0-9]{5}$/,
              })}
            />

            {errors.zipCode && errors.zipCode.type === "required" && (
              <span>Please enter your Zip Code</span>
            )}
            {errors.zipCode && errors.zipCode.type === "pattern" && (
              <span>Entered zip code is not valid</span>
            )}
          </div>
          <div className="form-control">
            <label>State*</label>
            <input
              type="text"
              name="state"
              id="state"
              defaultValue={contentDescription.state}
              ref={register({
                required: true,
                pattern: /^[A-Za-z]+$/,
              })}
            />
            {errors.state && errors.state.type === "required" && (
              <span>Please enter your state.</span>
            )}
            {errors.state && errors.state.type === "pattern" && (
              <span>Please don't enter numbers.</span>
            )}
          </div>
          {customerAddressMsg ? customerAddressMsg : ""}

          <div
            className={`single-address-submit-btn ${
              customerAddressLoading ? "disabled" : ""
            }`}
          >
            <button onClick={changeEditAddress}>{"Cancel"}</button>

            <button type="submit">
              {customerAddressLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default SingleAddressTheme2;
