import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import AdsSpaceSectionMain from "./AdsSpaceSectionMain";
import AdsSpaceSection from "./AdsSpaceSection";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import ProductListingPageAction from "components/ProductListingPage/Actions";
import ProductListingPageSidebar from "components/ProductListingPage/Sidebar";
import {
  getCateringDepartmentTree,
  getCateringProductList,
} from "redux/actions";
import "./catering.scss";
import CateringProductList from "./CateringProductList";
import { getLocalStorageValue } from 'config/helper';

const Catering = () => {
  const { departmentSlug } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const category_ids = searchParams.get("category_ids");

  const { cateringDepartmentTree } = useSelector(
    ({ cateringAndMeal }) => cateringAndMeal
  );

  const breadcrumbList = [
    { lable: "Catering & meals", link: "", searchTag: "" },
  ];
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const { activePageNo } = useSelector(({ product }) => product);
  const productList = useSelector(
    ({ cateringAndMeal }) => cateringAndMeal.cateringList
  );

  const categoryadsSection = useSelector(
    ({ cateringAndMeal }) => cateringAndMeal.categorySection
  );

  const { cateringListTotalPages, cateringListTotalRecords } = useSelector(
    ({ cateringAndMeal }) => cateringAndMeal
  );

  const departmentDetails =
    cateringDepartmentTree &&
    cateringDepartmentTree.find((ecom) => ecom.slug === departmentSlug);

  const selectedCategoryId = category_ids
    ? category_ids
    : departmentDetails
      ? departmentDetails.category_ids
      : "8652";

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      search: "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    !cateringDepartmentTree.length && dispatch(getCateringDepartmentTree(body));
  }, [storeId, isUserLoggedIn, memberNumber, userToken]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      PageNo: activePageNo,
      category_ids: selectedCategoryId,
      ift: "",
      limit: "20",
      sort_by: "",
      min_price: "",
      max_price: "",
      funtional_text: "",
      funtional_level: "",
      function_names: "",
      category_slug: "catering-mealkit",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };

    dispatch(getCateringProductList(body));
  }, [
    dispatch,
    departmentDetails,
    storeId,
    clientId,
    isUserLoggedIn,
    memberNumber,
    userToken,
    selectedCategoryId,
    activePageNo
  ]); // eslint-disable-line

  const updatedDepartmentTree = cateringDepartmentTree
    ? cateringDepartmentTree
      .filter((fld) => fld.slug === departmentSlug)
      .map((department) => {
        return {
          ...department,
          text: department.text,
        };
      })
    : [];
  const updatedOtherDepartmentTree = cateringDepartmentTree
    ? cateringDepartmentTree
      .filter((fld) => fld.slug !== departmentSlug)
      .map((department) => {
        return {
          ...department,
          name: department.text,
        };
      })
    : [];
  return (
    <div className="catering-page">
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={""} />
      <AdsSpaceSectionMain adsDetails={categoryadsSection} />
      <div className="container">
        <div className="catering-page-wrapper">
          <div className="catering-wrapper-items">
            <ProductListingPageSidebar
              departmentTree={updatedDepartmentTree}
              otherDepartmentTree={updatedOtherDepartmentTree}
              isCateringDepartment={true}
              redirectBaseUrl="catering-meal"
              querySearchKey="category_ids"
            />
          </div>
          <div className="listing-page-wrapper-items">
            <AdsSpaceSection
              adsDetails={categoryadsSection}
              department={departmentDetails && departmentDetails.text}
            />
            <ProductListingPageAction
              pageLimit={20}
              setPageLimit={() => { }}
              productListByDepartmentTotalCount={cateringListTotalRecords}
              departmentTree={updatedDepartmentTree}
              otherDepartmentTree={updatedOtherDepartmentTree}
              iftOptionList={[]}
              redirectBaseUrl="catering-meal"
              querySearchKey="category_ids"
            />
            <CateringProductList
              activePageNo={activePageNo}
              productList={productList}
              productListByDepartmentTotalPages={cateringListTotalPages}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catering;
