import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "components/ProductCard";
import ProductCardTheme2 from "components/ProductCardTheme2";
import Pagination from "components/Common/Pagination";
import { updateActivePage } from "redux/actions";
import "./product-items.scss";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";


const ProductList = ({
  productList,
  activePageNo,
  productListByDepartmentTotalPages,
  bannerData
}) => {
  const dispatch = useDispatch();
  
  const [page, setPage] = useState(activePageNo);

  const { theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleSetPage = (data) => {
      dispatch(updateActivePage(data));
      scrollToTop();
      setPage(data);
  };
  // Split productList into two separate lists if bannerData is not null
  let firstHalf = productList;
  let secondHalf = [];
  if (bannerData && bannerData.length > 0 && productList.length > 10) {
    const middleIndex = Math.floor(productList.length / 2);
    firstHalf = productList.slice(0, middleIndex);
    secondHalf = productList.slice(middleIndex);
  }

  return (
    <div className="deal-all-wrapper">
      <div className="product-list-grid">
        {theme_type === "1" && firstHalf.length > 0 ?
          (firstHalf.map((product, index) => {
            return (
              <div className="product-list-grid-items" key={product.product_id || index}>
                <ProductCard product={product} />
              </div>
            );
          })) : (
            firstHalf.map((product, index) => {
              return (
                <div className="product-list-grid-items" key={product.product_id || index}>
                  <ProductCardTheme2 product={product} />
                </div>
              );
            })
          )
        }
      </div>
      {bannerData && bannerData.length > 0 && <AdsSpaceSectionMainTheme2 adsDetails={bannerData} />}
      <div className="product-list-grid">
        {theme_type === "1" && secondHalf.length > 0 ?
          (secondHalf.map((product, index) => {
            return (
              <div className="product-list-grid-items" key={product.product_id || index}>
                <ProductCard product={product} />
              </div>
            );
          })) : (
            secondHalf.map((product, index) => {
              return (
                <div className="product-list-grid-items" key={product.product_id || index}>
                  <ProductCardTheme2 product={product} />
                </div>
              );
            })
          )
        }
      </div>
      {productList.length ? (
        <Pagination
          pages={productListByDepartmentTotalPages}
          current={page}
          onClick={handleSetPage}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductList;
