import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  // getRemoveProductToCartList,
  // editProductToCartList,
  addToWishlist,
} from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AddToMyList from "components/AddToMyList";
import { getLocalStorageValue } from 'config/helper';

import "./catering-product-card.scss";

const CateringProductCard = ({ product, addButtonText }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");

  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { cateringAndMealKitProductCartList, addProductToCartListLoading, wishListItemsLoading } =
    useSelector(({ cartWishlist }) => cartWishlist);

  const productId = product.product_id;
  const addedProcduct =
    cateringAndMealKitProductCartList &&
    cateringAndMealKitProductCartList.find((product) => product.product_id === productId);

  // const itemId = addedProcduct && addedProcduct.item_id;
  const cartProductQuantity = addedProcduct && addedProcduct.qty;

  const [isShowMyListDropdown, setShowMyListDropdown] = useState(false);
  const [isShowNewListModal, setShowNewListModal] = useState(false);
  const [productQuantity,] = useState(
    +cartProductQuantity || 1
  );
  // setAddingProductToCart
  const [isAddingProductToCart] = useState(false);

  // const handleIncrementCount = () => {
  //   setProductQuantity(productQuantity + 1);
  //   handleUpdateProductToCart(productQuantity + 1, productId, itemId);
  // };
  // const handleDecrementCount = () => {
  //   if (productQuantity > 1) {
  //     setProductQuantity(productQuantity - 1);
  //     handleUpdateProductToCart(productQuantity - 1, productId, itemId);
  //   } else {
  //     addedProcduct && handleRemoveProductToCart(itemId);
  //     setProductQuantity(1);
  //   }
  // };
  const handleAddToCart = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
      return;
    }
    navigate(
      `/catering-product-description/${product.department_slug}/${product.slug}`
    );
  };

  const handleAddToWishlist = (list_id) => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id: product.product_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      qty: productQuantity,
      list_id,
    };

    dispatch(addToWishlist(body)).then(() => {
      setShowMyListDropdown(false);
    });
  };

  // const handleRemoveProductToCart = (item_id) => {
  //   const body = {
  //     RSAClientId: clientId,
  //     ClientStoreId: +storeId || 1,
  //     item_id,
  //     member_number: memberNumber,
  //     user_token: userToken,
  //     AppName: "shop",
  //     DeviceType: "web",
  //     Version: "1",
  //   };
  //   dispatch(getRemoveProductToCartList(body));
  // };

  // const handleUpdateProductToCart = (quantity, product_id, item_id) => {
  //   const body = {
  //     RSAClientId: clientId,
  //     ClientStoreId: +storeId || 1,
  //     product_id,
  //     item_id,
  //     qty: quantity,
  //     member_number: memberNumber,
  //     user_token: userToken,
  //     AppName: "shop",
  //     DeviceType: "web",
  //     Version: "1",
  //     product_note: "",
  //     custom_options_arr: "",
  //   };
  //   quantity >= 1 && dispatch(editProductToCartList(body));
  // };

  return (
    <div className="catering-product-card" key={product.id}>
       {isUserLoggedIn ? (
            <AddToMyList
              isShowMyListDropdown={isShowMyListDropdown}
              isShowNewListModal={isShowNewListModal}
              setShowNewListModal={setShowNewListModal}
              setShowMyListDropdown={setShowMyListDropdown}
              title=""
              handleApply={handleAddToWishlist}
              loading={wishListItemsLoading}
              isShowIcon={true}
            />
          ) : (
            ""
          )}
      <div className="product-card-image" tabIndex="0">
        <Link
          to={`/catering-product-description/${product.department_slug}/${product.slug}`}
        >
          <img src={product.image} alt="ProductImage" />
        </Link>
      </div>
      <div className="product-card-details">
        <h3>{product.department_name}</h3>
        <Link
          to={`/catering-product-description/${product.department_slug}/${product.slug}`}
        >
          {product.name}
        </Link>

          <span className="start-label">Starting from</span>
        <div className="price">
          <h3>{product.price1}</h3>
        </div>
        <div className="button-group-alignment">
          {/* {isUserLoggedIn ? (
            <div className=" button-group">
              <div className="action-button">
                <i className="fa-solid fa-minus"></i>
              </div>
              <div className="counter">
                {(addedProcduct && addedProcduct.qty) || productQuantity}
              </div>
              <div
                className="action-button-plus"

              >
                <i className="fa-solid fa-plus"></i>
              </div>
            </div>
          ) : (
            <div></div>
          )} */}
          <div className="add-to-cart">
            <button onClick={handleAddToCart} disabled={addedProcduct}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="11.575"
                viewBox="0 0 14 11.575"
              >
                <g
                  id="Group_111"
                  data-name="Group 111"
                  transform="translate(-132.997 -1553.667)"
                >
                  <g
                    id="Group_111-2"
                    data-name="Group 111"
                    transform="translate(133.997 1554.667)"
                  >
                    <path
                      id="Subtraction_1"
                      data-name="Subtraction 1"
                      d="M9.539,8.826a.244.244,0,0,1-.127-.039H4.7a.227.227,0,0,1-.254,0c-.046,0-.1,0-.16,0H4.038a2.346,2.346,0,0,1-.319-.018,1.075,1.075,0,0,1-.883-.851,1.1,1.1,0,0,1,.516-1.173.123.123,0,0,0,.074-.165C2.988,4.55,2.561,2.548,2.2.858,2.186.788,2.158.766,2.085.766H1.162c-.19,0-.441,0-.693.006H.45A.435.435,0,0,1,0,.5V.306A.359.359,0,0,1,.4,0L1.463,0h.919a.431.431,0,0,1,.473.391c.042.188.083.379.122.565l0,.01.014.066.045.213c.046.212.046.212.253.212h8.277a.628.628,0,0,1,.191.022A.33.33,0,0,1,12,1.788v.17a6.265,6.265,0,0,0-.19.636c-.022.085-.044.168-.066.251-.276.993-.546,1.972-.845,3.058L10.6,7a.375.375,0,0,1-.425.328H3.895a.4.4,0,0,0-.118.014.365.365,0,0,0-.26.384.361.361,0,0,0,.334.33l.065,0h6.293a.49.49,0,0,1,.1.007.364.364,0,0,1,.287.386.357.357,0,0,1-.341.334c-.134,0-.269,0-.4,0H9.666A.243.243,0,0,1,9.539,8.826ZM6.859,6.6l3.008,0a.122.122,0,0,0,.142-.109c.2-.723.394-1.436.612-2.227l.224-.812c.112-.407.223-.813.336-1.224H3.218c0,.006,0,.01,0,.014a.077.077,0,0,0,0,.017l.147.683.056.261c.232,1.083.472,2.2.706,3.3.018.086.06.093.128.093Z"
                      transform="translate(0 0)"
                      fill="#fff"
                      stroke="rgba(0,0,0,0)"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_63"
                      data-name="Path 63"
                      d="M369.843,385.073h.215a.93.93,0,0,1,.612.366.858.858,0,0,1,.1.874.833.833,0,0,1-.678.531.883.883,0,0,1-1.035-.828.9.9,0,0,1,.729-.927C369.807,385.085,369.828,385.09,369.843,385.073Z"
                      transform="translate(-365.426 -376.279)"
                      fill="#fff"
                    />
                    <path
                      id="Path_64"
                      data-name="Path 64"
                      d="M581.767,385.073h.215a.93.93,0,0,1,.611.366.857.857,0,0,1,.1.874.833.833,0,0,1-.678.531.883.883,0,0,1-1.035-.828.9.9,0,0,1,.729-.927C581.731,385.085,581.752,385.09,581.767,385.073Z"
                      transform="translate(-571.964 -376.279)"
                      fill="#fff"
                    />
                    <path
                      id="Path_66"
                      data-name="Path 66"
                      d="M399.355,415.569a.3.3,0,1,1,.3-.294A.3.3,0,0,1,399.355,415.569Z"
                      transform="translate(-394.799 -405.584)"
                      fill="#fff"
                    />
                    <path
                      id="Path_67"
                      data-name="Path 67"
                      d="M611.574,415.273a.3.3,0,1,1-.3-.3A.3.3,0,0,1,611.574,415.273Z"
                      transform="translate(-601.704 -405.586)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </svg>
              <span>
                {isUserLoggedIn
                  ? addProductToCartListLoading && isAddingProductToCart
                    ? "Adding..."
                    : addedProcduct
                      ? "Added"
                      : addButtonText || "Add to Order"
                  : "Sign in to Add"}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CateringProductCard;
